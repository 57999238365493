var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{staticClass:"orders-table",attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('identifier',{attrs:{"item":item,"get-id":_vm.getIdFromIri,"show-edit-link":_vm.isSuperAdmin || _vm.isCompanyAdmin,"route-type":_vm.routeType}}),(
                            _vm.isSuperAdmin &&
                            ((item.deliveryJob &&
                                item.deliveryJob.warning) ||
                                item.warning)
                        )?_c('warning',{attrs:{"item":item},on:{"execute":function($event){return _vm.removeWarning(item.id)}}}):_vm._e()]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('status',{attrs:{"item":item}})]}},{key:"item.deliveryJob",fn:function(ref){
                        var item = ref.item;
return [_c('delivery-status',{attrs:{"item":item}})]}},{key:"item.deliveryJob.pickupTime",fn:function(ref){
                        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getIdFromIri(
                                item,
                                'restaurant',
                                _vm.routeType.RESTAURANT_EDIT,
                                'id'
                            )}},[_vm._v(" "+_vm._s(item.restaurantName)+" ")]),(
                            item.deliveryJob &&
                            item.deliveryJob.primaryMethod !==
                                'restaurant_managed'
                        )?_c('small',{staticClass:"d-flex flex-nowrap g-73--text"},[_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryJob.pickupTime))+" ")]):_vm._e()]}},{key:"item.requiredDeliveryTime",fn:function(ref){
                        var item = ref.item;
return [_c('drop-off',{attrs:{"item":item}})]}},{key:"item.deliveryName",fn:function(ref){
                        var item = ref.item;
return [(item.userProfile)?_c('router-link',{attrs:{"to":_vm.getIdFromIri(
                                item.userProfile,
                                '@id',
                                _vm.routeType.USER_EDIT,
                                'userId'
                            )}},[_vm._v(" "+_vm._s(_vm._f("toFullName")(item.userProfile))+" ")]):[_vm._v(" "+_vm._s(item.deliveryName)+" ")],(
                            item.companyName &&
                            item.companyName !== item.deliveryName
                        )?_c('small',{staticClass:"d-flex g-73--text"},[_vm._v(" "+_vm._s(item.companyName)+" ")]):_vm._e()]}},{key:"item.priceTotal",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.priceTotal,item.currency, item.currencyLocale))+" "),_c('small',{staticClass:"d-flex g-73--text"},[_vm._v(" "+_vm._s(_vm.$t( 'enums.payment_methods.' + (item.paymentMethod || 'company') ))+" ")])]}},{key:"item.sent",fn:function(ref){
                        var item = ref.item;
return [_c('flag',{attrs:{"active":parseFloat(item.sentRatio || 0) >= 1}})]}},{key:"item.costCode",fn:function(ref){
                        var item = ref.item;
return [_c('edit-cost-code',{attrs:{"item":item,"api":_vm.api,"update-list":_vm.updateTableItems}})]}},(_vm.isSuperAdmin)?{key:"rowActions",fn:function(ref){
                        var item = ref.item;
return [_c('table-actions',{attrs:{"item":item,"api":_vm.api,"update-table-items":_vm.updateTableItems}})]}}:null],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }