export default {
    USERS: 'users',
    DELIVERY_SETTINGS: 'deliverySettings',
    PROMOTIONS: 'promotions',
    COMPANIES: 'companies',
    COMPANY_ADDRESSES: 'companyAddresses',
    COMPANY_ALLOWANCES: 'companyAllowances',
    COMPANY_BALANCES: 'companyBalances',
    COMPANY_INVOICE: 'companyInvoice',
    COMPANY_CLOUDCANTEENS: 'companyCloudcanteens',
    COMPANY_TEAMS: 'companyTeams',
    USER_ADDRESSES: 'userAddresses',
    ORDERS: 'orders',
    ORDERS_PUBLIC: 'ordersPublic',
    CANTEENS: 'canteens',
    RESTAURANTS: 'restaurants',
    MEAL_CATEGORIES: 'mealCategories',
    MEAL_OPTION_GROUP: 'mealOptionGroups',
    MEAL_OPTION: 'mealOptions',
    MEAL: 'meals',
    NOTIFICATION: 'notification',
    TRANSLATIONS: 'translations',
    TAGS: 'tags',
    TAG_GROUPS: 'tagGroups',
    DELIVERIES: 'deliveries',
    RESIDENTIAL_CANTEENS: 'residentialCanteens',
    RESIDENTIAL_CANTEEN_ADDRESSES: 'residentialCanteenAddresses',
    RESIDENTIAL_CANTEEN_RELATIONS: 'residentialCanteenRelations',
    AUDITLOG: 'auditlog',
    CUSTOM_EXPORT: 'customExport',
    COUPONS: 'coupons',
    INVOICES: 'invoices',
}
