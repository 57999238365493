import ResponseHandler from '@/api/response/ResponseHandler'
import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import orderType from '@/store/type/orderType'
import apiClient from '@/api/ApiClient'
import Store from '@/store/store'

class OrdersApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/orders'
    }
    getBaseUrlV2() {
        return '/api/v1/orders/v2'
    }

    update(entity, id) {
        return ResponseHandler.handle(
            super.update(entity, id),
            orderType.mutations.SET_ACTIVE_ORDER
        )
    }

    getHistory(after, before) {
        return super
            .list({
                'requiredDeliveryTime[after]': after,
                'requiredDeliveryTime[before]': before,
            })
            .then((data) => {
                Store.dispatch(
                    orderType.actions.SET_HISTORY_ORDERS_LIST,
                    data['hydra:member']
                )
                return data
            })
    }

    approve(id, entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'approve'), entity)
        )
    }

    cancel(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'cancel'))
        )
    }
    confirm(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'preparation-confirm'))
        )
    }

    downloadLabel(restaurantId, ccId) {
        return ResponseHandler.handle(
            apiClient.get(
                `/api/v1/cloud-canteen/label/view/${ccId}/${restaurantId}`,
                {
                    responseType: 'blob',
                }
            )
        )
    }

    refund(id, entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'refund'), entity)
        )
    }

    previewEmail(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'confirmation-email-preview'))
        )
    }

    mailSend(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'send-out/ordit'))
        )
    }

    getDeliveryJobDefaultValues(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'default-delivery-job-values'))
        )
    }
    removeDeliveryWarning(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clear-delivery-warnings'))
        )
    }

    downloadMealList(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'download/meal-info-list'), {
                responseType: 'blob',
            })
        )
    }

    downloadSummary(identifier, token) {
        let path = `/api/v1/cloud-canteen/summary/${identifier}`
        if (token) {
            path += `?token=${token}`
        }
        return ResponseHandler.handle(
            apiClient.get(path, {
                responseType: 'blob',
            })
        )
    }
}

export default new OrdersApiClient()
