import SelectApiClientType from '@/api/SelectApiClientType'

export default {
    computed: {
        listFilters() {
            const filters = [
                {
                    key: 'canteen',
                    label: 'labels.company_cloudcanteens',
                    value: 'true',
                    inputType: 'checkbox',
                },
                {
                    key: 'status',
                    label: 'enums.order_status.new',
                    value: 'new',
                    inputType: 'checkbox',
                },
                {
                    key: 'requiredDeliveryTime',
                    inputType: 'checkbox',
                    label: 'labels.today_filter',
                    type: 'after',
                    value: this.$date().startOf('day').format(),
                    other: {
                        key: 'requiredDeliveryTime',
                        type: 'before',
                        value: this.$date().endOf('day').format(),
                    },
                    blocker: 'requiredDeliveryTime',
                    dateValidation: 'day',
                },
                {
                    key: 'requiredDeliveryTime',
                    inputType: 'checkbox',
                    label: 'labels.tomorrow_filter',
                    type: 'after',
                    value: this.$date().startOf('day').add(1, 'day').format(),
                    other: {
                        key: 'requiredDeliveryTime',
                        type: 'before',
                        value: this.$date().endOf('day').add(1, 'day').format(),
                    },
                    blocker: 'requiredDeliveryTime',
                    dateValidation: 'day',
                },
                {
                    key: 'notInInitPayment',
                    label: 'labels.init_payment_filter',
                    value: '1',
                    inputType: 'checkbox',
                },
                {
                    key: 'deliveryWarning',
                    label: 'labels.delivery_warning',
                    value: 1,
                    inputType: 'checkbox',
                },
                {
                    key: 'hideCompleted',
                    label: 'labels.hide_completed',
                    value: 1,
                    inputType: 'checkbox',
                },
                {
                    key: 'hideSubCanteen',
                    label: 'labels.hide_subcanteen',
                    value: 1,
                    inputType: 'checkbox',
                },
                {
                    key: 'paymentMethod',
                    label: 'labels.payment_method',
                    inputType: 'select',
                    translate: true,
                    entity: SelectApiClientType.PAYMENT_METHODS,
                },
                {
                    key: 'status',
                    label: 'labels.order_status',
                    inputType: 'select',
                    translate: true,
                    entity: SelectApiClientType.ORDER_STATUS,
                },
            ]
            if (this.isSuperAdmin) {
                filters.push({
                    key: 'company.id',
                    label: 'labels.company',
                    inputType: 'select',
                    useIdField: true,
                    multiple: true,
                    entity: SelectApiClientType.COMPANY,
                })
            }
            filters.push(
                {
                    key: 'couponCode',
                    type: 'partial',
                    label: this.$t('labels.coupon_code'),
                    inputType: 'search',
                },
                {
                    key: 'requiredDeliveryTime',
                    type: 'after',
                    label: 'labels.delivery_time_from',
                    inputType: 'date',
                    listener: 'requiredDeliveryTime',
                },
                {
                    key: 'requiredDeliveryTime',
                    type: 'before',
                    label: 'labels.delivery_time_until',
                    inputType: 'date',
                    listener: 'requiredDeliveryTime',
                },
                {
                    key: 'createdAt',
                    type: 'after',
                    label: 'labels.order_date_from',
                    inputType: 'date',
                },
                {
                    key: 'createdAt',
                    type: 'before',
                    label: 'labels.order_date_until',
                    inputType: 'date',
                }
            )
            return filters
        },
    },
}
