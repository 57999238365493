<script>
import ApproveOrder from '@/components/pages/restaurantAdmin/confirm/ApproveOrder'
import ConfirmOrder from '@/components/pages/restaurantAdmin/confirm/ConfirmOrder'
import PageTitle from '@/components/layout/components/pageTitle'
import authType from '@/store/type/authType'
import routeType from '@/router/routeType'
import { mapGetters } from 'vuex'
import NotificationAlert from '@/components/pages/restaurants/NotificationAlert'
import ManageOrders from '@/components/pages/restaurantAdmin/confirm/ManageOrders'
import AuthMixin from '@/components/mixins/AuthMixin'
import OrderEvent from '@/pages/restaurantAdmin/mixins/OrderEvent'

export default {
    name: 'Manager',
    components: {
        NotificationAlert,
        ManageOrders,
        ConfirmOrder,
        ApproveOrder,
        PageTitle,
    },
    mixins: [AuthMixin, OrderEvent],
    data() {
        return {
            routeType,
        }
    },
    computed: {
        ...mapGetters({
            notificationRegistered: authType.getters.GET_NOTIFICATION_STATE,
            userId: authType.getters.USER_ID,
            isLoggedIn: authType.getters.IS_LOGGED_IN,
        }),
        isActive() {
            return this.$route.name === routeType.ORDER_LIST
        },
        isOpen() {
            return this.$route.name === routeType.ORDER_OPEN
        },
        isHistory() {
            return this.$route.name === routeType.ORDER_HISTORY
        },
    },
}
</script>

<template lang="pug">
    .restaurant-admin
        notification-alert(v-if="isRestaurantAdmin && isLoggedIn")
        page-title {{ $t('orders.list_page_title') }}
        v-tabs.mb-3.border-bottom-primary(background-color="transparent")
            v-tab(:to="{ name: routeType.ORDER_ACTIVE }" :class="{'v-tab--active pointer-none': isActive}"  exact) {{ $t('labels.active') }}
            v-tab(:to="{ name: routeType.ORDER_OPEN }" :class="{'v-tab--active pointer-none': isOpen}" ) {{ $t('labels.open') }}
            v-tab(:to="{ name: routeType.ORDER_HISTORY, params: { history: 'today' } }" :class="{'v-tab--active pointer-none': isHistory}" exact) {{ $t('labels.history') }}
        router-view

        manage-orders(v-if="isRestaurantAdmin && isLoggedIn")
</template>
