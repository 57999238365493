<script>
import { mapGetters } from 'vuex'
import orderType from '@/store/type/orderType'

export default {
    name: 'ConfirmButton',
    props: {
        list: Array,
        index: Number,
        disabled: Boolean,
    },
    data() {
        return {
            height: undefined,
        }
    },
    computed: {
        ...mapGetters({
            loading: orderType.loaders.CONFIRM_ORDER_LOADING,
        }),
    },
    methods: {
        confirm() {
            this.$store
                .dispatch(
                    orderType.actions.CONFIRM_ORDER,
                    this.list[this.index].id
                )
                .then(() => {
                    this.$store
                        .dispatch(orderType.actions.GET_ORDERS)
                        .then(() => {
                            if (this.list?.length) {
                                this.$store.dispatch(
                                    orderType.actions.GET_ORDER,
                                    [
                                        this.list[this.index].id,
                                        orderType.mutations
                                            .UPDATE_APPROVED_ORDERS_LIST,
                                    ]
                                )
                            }
                        })
                })
        },
    },
}
</script>

<template lang="pug">
.confirm-preparation(ref="confirmPreparation")
    v-btn(@click="confirm()" :disabled="loading || disabled" color="info" block) {{ $t('orders.confirm_prep') }}
    slot(name="total")
</template>

<style scoped lang="scss"></style>
