export default {
    getId: (iri) => {
        return iri && (typeof iri === 'string' || iri instanceof String)
            ? iri.substr(
                  iri.lastIndexOf('/') + 1,
                  iri.length - iri.lastIndexOf('/')
              )
            : null
    },
}
