import { ROLE_ADMIN } from '@/enum/roles'
import routeType from '@/router/routeType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    layout: 'ordit-authenticated-layout',
}

export default [
    {
        path: '/tablets',
        component: {
            render: (c) => c('router-view'),
        },
        meta,
        children: [
            {
                path: '',
                name: routeType.TABLETS_CONFIG,
                component: () =>
                    import(
                        /* webpackChunkName: "tablets" */ '@/pages/tablets/Config.vue'
                    ),
                meta,
            },
        ],
    },
]
