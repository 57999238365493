<script>
import ActiveOrder from '@/components/pages/restaurantAdmin/ActiveOrder'
import ConfirmButton from '@/components/pages/restaurantAdmin/confirm/ConfirmButton'
import ConfirmMixin from '@/components/mixins/ConfirmMixin'
import orderType from '@/store/type/orderType'

export default {
    name: 'ConfirmOrder',
    components: { ConfirmButton, ActiveOrder },
    mixins: [ConfirmMixin],
    data() {
        return {
            timer: null,
            now: null,
        }
    },
    computed: {
        ordersNeedConfirmation() {
            return (
                this.approvedOrdersList?.filter(
                    (order) =>
                        !order.preparationConfirmed &&
                        Boolean(order.userProfile) && //this is not a CC order (in CC orders the userProfile is null)
                        this.now &&
                        this.now
                            .add(0.5, 'minute')
                            .isSameOrAfter(order.startPrepareAt)
                ) || []
            )
        },
        openConfirmation() {
            return !this.approveOpen && this.ordersNeedConfirmation.length > 0
        },
        needNavigation() {
            return this.ordersNeedConfirmation.length > 1
        },
    },
    watch: {
        openConfirmation: {
            immediate: true,
            handler(openConfirmation) {
                if (!this.approveOpen) {
                    this.$store.commit(
                        orderType.mutations.SET_CONFIRM_STATE,
                        openConfirmation
                    )
                    if (openConfirmation) this.updateList()
                }
            },
        },
        // approvedOrdersList: {
        //     immediate: true,
        //     deep: true,
        //     handler(list, oldList) {
        //         if (!isEqual(list, oldList)) {
        //             if (this.ordersNeedConfirmation.length) {
        //
        //                 console.log("2")
        //                 this.updateList()}
        //         }
        //     },
        // },
    },
    created() {
        this.startClock()
    },
    beforeDestroy() {
        this.stopClock()
    },
    methods: {
        startClock() {
            if (!this.now) this.now = this.$date()
            this.timer = setInterval(() => {
                this.now = this.$date()
            }, 60000)
        },
        stopClock() {
            clearInterval(this.timer)
        },
        next() {
            this.$refs.carousel.next()
            if (!this.ordersNeedConfirmation[this.active].meals)
                this.updateList()
        },
        prev() {
            this.$refs.carousel.prev()
            if (!this.ordersNeedConfirmation[this.active].meals)
                this.updateList()
        },
        updateList() {
            this.$store.dispatch(orderType.actions.GET_ORDER, [
                this.ordersNeedConfirmation[this.active].id,
                orderType.mutations.UPDATE_APPROVED_ORDERS_LIST,
            ])
        },
    },
}
</script>

<template lang="pug">
    v-dialog(:value="openConfirmation" content-class="confirm-order" :max-width="width" overlay-color="black" :overlay-opacity=".5" persistent light)
        v-card.px-3(:loading="loadingConfirm" height="100%")
            v-carousel(v-model="active" ref="carousel" height="100%" :show-arrows="false" hide-delimiters light hide-delimiter-background)
                v-carousel-item(v-for="(order, index) in ordersNeedConfirmation" :key="`incomingOrder-${index}`")
                    v-container.pt-3.mh-100.h-100.d-flex.flex-column.overflow-y-auto(fluid)
                        active-order(:order="order" confirmToPrepare)
            confirm-button(
                :list="ordersNeedConfirmation"
                :index="active"
                :disabled="loadingConfirm || loadingList")
                .total(slot="total") {{active + 1}}/{{ordersNeedConfirmation.length}}

        v-fade-transition
            .navigation-wrapper(v-if="needNavigation")
                v-btn(@click="prev" color="g-f7" icon plain)
                    v-icon arrow_back
                v-btn(@click="next" color="g-f7" icon plain)
                    v-icon arrow_forward

</template>
