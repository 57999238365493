import { ROLE_ADMIN, ROLE_COMPANY, ROLE_RESTAURANT } from '@/enum/roles'
import routeType from '@/router/routeType'
import store from '@/store/store'
import authType from '@/store/type/authType'
import View from '@/pages/restaurantAdmin/View'
import List from '@/pages/orders/List'

let isRestaurantAdmin = () =>
    store.getters[authType.getters.IS_RESTAURANT_ADMIN]

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN, ROLE_COMPANY, ROLE_RESTAURANT],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    list: {
        to: {
            name: routeType.ORDER_LIST,
        },
        text: 'menu.orders',
    },
    edit: {
        to: {
            name: routeType.ORDER_EDIT,
        },
        label: '...',
    },
}

export default [
    {
        path: '/orders',
        component: {
            render: (c) => c('router-view'),
        },
        meta: {
            ...meta,
            breadcrumb: breadCrumbs.list,
        },
        children: [
            {
                path: '/',
                name: routeType.ORDER_LIST,
                component: {
                    render: (h) => {
                        if (isRestaurantAdmin()) return h(View)
                        else return h(List)
                    },
                },
                meta,
                children: [
                    {
                        path: 'open/:restaurant?/:id?',
                        name: routeType.ORDER_OPEN,
                        component: () =>
                            import(
                                /* webpackChunkName: "order-edit" */ '@/pages/restaurantAdmin/view/Open'
                            ),
                        meta,
                    },
                    {
                        path: 'history/:history/:id?',
                        name: routeType.ORDER_HISTORY,
                        component: () =>
                            import(
                                /* webpackChunkName: "order-edit" */ '@/pages/restaurantAdmin/view/History'
                            ),
                        meta,
                    },
                    {
                        path: 'active/:id?',
                        name: routeType.ORDER_ACTIVE,
                        component: () =>
                            import(
                                /* webpackChunkName: "order-edit" */ '@/pages/restaurantAdmin/view/Active'
                            ),
                        meta,
                    },
                ],
            },
            {
                path: ':id?',
                name: routeType.ORDER_EDIT,
                component: () =>
                    import(
                        /* webpackChunkName: "order-edit" */ '@/pages/orders/Edit'
                    ),
                meta: {
                    ...meta,
                    roles: [ROLE_ADMIN, ROLE_COMPANY],
                    breadcrumb: breadCrumbs.edit,
                },
            },
            {
                path: ':id/preview-email',
                name: routeType.ORDER_PREVIEW_EMAIL,
                component: () =>
                    import(
                        /* webpackChunkName: "order-preview-email" */ '@/pages/orders/Preview'
                    ),
                meta: {
                    ...meta,
                    breadcrumb: breadCrumbs.edit,
                },
            },
        ],
    },
]
