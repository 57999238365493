import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import apiClient from '@/api/ApiClient'
import ResponseHandler from '@/api/response/ResponseHandler'
import SelectApiClientType from '@/api/SelectApiClientType'
import restaurantType from '@/store/type/restaurantType'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'

class RestaurantsApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/restaurants'
    }
    get(id) {
        return ResponseHandler.handle(
            super.get(id),
            restaurantType.mutations.SET_ACTIVE_RESTAURANT,
            MapperCollection.get(MapperType.RESTAURANT_FORM)
        )
    }
    activate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { isActive: true })
        )
    }

    deactivate(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), { isActive: false })
        )
    }

    restaurantAdminEnable(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), {
                isEnabledForRestaurantAdmin: true,
            })
        )
    }

    restaurantAdminDisable(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id), {
                isEnabledForRestaurantAdmin: false,
            })
        )
    }

    generateAccessToken(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'access-token'))
        )
    }

    deleteAccessToken(id) {
        return ResponseHandler.handle(
            apiClient.delete(this.getItemIri(id, 'access-token'))
        )
    }

    getRelatedSelectApi() {
        return SelectApiClientType.RESTAURANT
    }

    exportCategories(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'export-categories'))
        )
    }

    exportMeals(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'export-meals'))
        )
    }

    exportOptions(id) {
        return ResponseHandler.handle(
            apiClient.get(this.getItemIri(id, 'export-option-groups'))
        )
    }

    import(id, formData) {
        return ResponseHandler.handle(
            apiClient.post(this.getItemIri(id, 'import-menu'), formData, {
                'Content-Type': 'multipart/form-data',
            })
        )
    }

    clone(id) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'clone'))
        )
    }
}

export default new RestaurantsApiClient()
