import { ROLE_ADMIN, ROLE_COMPANY } from '@/enum/roles'
import routeType from '@/router/routeType'
import SelectApiClientType from '@/api/SelectApiClientType'

const meta = {
    requiresAuth: true,
    roles: [ROLE_ADMIN, ROLE_COMPANY],
    layout: 'ordit-authenticated-layout',
}

const breadCrumbs = {
    companyList: {
        to: {
            name: routeType.COMPANY_LIST,
        },
        label: 'menu.companies',
        roles: [ROLE_ADMIN],
    },
    companyEdit: {
        to: {
            name: routeType.COMPANY_EDIT,
            query: { tab: 0 },
        },
        label: {
            selectType: SelectApiClientType.COMPANY,
            idParamName: 'companyId',
        },
        roles: [ROLE_ADMIN],
    },
    addressList: {
        to: {
            name: routeType.COMPANY_ADDRESS_LIST,
        },
        label: 'labels.company_addresses',
        roles: [ROLE_ADMIN, ROLE_COMPANY],
    },
    allowanceList: {
        to: {
            name: routeType.COMPANY_ALLOWANCE_LIST,
        },
        label: 'labels.company_allowances',
        roles: [ROLE_ADMIN, ROLE_COMPANY],
    },
    cloudcanteenList: {
        to: {
            name: routeType.COMPANY_CLOUDCANTEEN_LIST,
        },
        label: 'labels.company_cloudcanteens',
        roles: [ROLE_ADMIN, ROLE_COMPANY],
    },
    teamsList: {
        to: {
            name: routeType.COMPANY_TEAMS_LIST,
        },
        label: 'labels.company_teams',
        roles: [ROLE_ADMIN, ROLE_COMPANY],
    },
}

const passthroughRender = {
    render: (c) => c('router-view'),
}

// @TODO disallow mixing any companyId with any unrelated addressId in url
const companyAddress = {
    path: 'addresses',
    component: passthroughRender,
    meta,
    children: [
        {
            path: '',
            name: routeType.COMPANY_ADDRESS_LIST,
            component: () =>
                import(
                    /* webpackChunkName: "company-address" */ '@/pages/companies/addresses/List.vue'
                ),
            props: true,
            meta,
        },
        {
            path: 'create',
            name: routeType.COMPANY_ADDRESS_CREATE,
            component: () =>
                import(
                    /* webpackChunkName: "company-address" */ '@/pages/companies/addresses/Create.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [breadCrumbs.companyEdit, breadCrumbs.addressList],
            },
        },
        {
            path: ':addressId',
            name: routeType.COMPANY_ADDRESS_EDIT,
            component: () =>
                import(
                    /* webpackChunkName: "company-address" */ '@/pages/companies/addresses/Edit.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [breadCrumbs.companyEdit, breadCrumbs.addressList],
            },
        },
    ],
}

const companyAllowance = {
    path: 'allowances',
    component: passthroughRender,
    meta,
    children: [
        {
            path: '',
            name: routeType.COMPANY_ALLOWANCE_LIST,
            component: () =>
                import(
                    /* webpackChunkName: "company-allowance" */ '@/pages/companies/allowances/List.vue'
                ),
            props: true,
            meta,
        },
        {
            path: 'create',
            name: routeType.COMPANY_ALLOWANCE_CREATE,
            component: () =>
                import(
                    /* webpackChunkName: "company-allowance" */ '@/pages/companies/allowances/Create.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [
                    breadCrumbs.companyEdit,
                    breadCrumbs.allowanceList,
                ],
            },
        },
        {
            path: ':allowanceId',
            name: routeType.COMPANY_ALLOWANCE_EDIT,
            component: () =>
                import(
                    /* webpackChunkName: "company-allowance" */ '@/pages/companies/allowances/Edit.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [
                    breadCrumbs.companyEdit,
                    breadCrumbs.allowanceList,
                ],
            },
        },
    ],
}

const companyCloudcanteen = {
    path: 'cloudcanteens',
    component: passthroughRender,
    meta,
    children: [
        {
            path: '',
            name: routeType.COMPANY_CLOUDCANTEEN_LIST,
            component: () =>
                import(
                    /* webpackChunkName: "company-cloudcanteen" */ '@/pages/companies/cloudcanteens/List.vue'
                ),
            props: true,
            meta,
        },
        {
            path: 'create',
            name: routeType.COMPANY_CLOUDCANTEEN_CREATE,
            component: () =>
                import(
                    /* webpackChunkName: "company-cloudcanteen" */ '@/pages/companies/cloudcanteens/Create.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [
                    breadCrumbs.companyEdit,
                    breadCrumbs.cloudcanteenList,
                ],
            },
        },
        {
            path: ':cloudcanteenId',
            name: routeType.COMPANY_CLOUDCANTEEN_EDIT,
            component: () =>
                import(
                    /* webpackChunkName: "company-cloudcanteen" */ '@/pages/companies/cloudcanteens/Edit.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [
                    breadCrumbs.companyEdit,
                    breadCrumbs.cloudcanteenList,
                ],
            },
        },
    ],
}

const companyTeams = {
    path: 'teams',
    component: passthroughRender,
    meta,
    children: [
        {
            path: '',
            name: routeType.COMPANY_TEAMS_LIST,
            component: () =>
                import(
                    /* webpackChunkName: "company-teams" */ '@/pages/companies/teams/List.vue'
                ),
            props: true,
            meta,
        },
        {
            path: 'create',
            name: routeType.COMPANY_TEAM_CREATE,
            component: () =>
                import(
                    /* webpackChunkName: "company-teams" */ '@/pages/companies/teams/Create.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [breadCrumbs.companyEdit, breadCrumbs.teamsList],
            },
        },
        {
            path: ':teamId',
            name: routeType.COMPANY_TEAM_EDIT,
            component: () =>
                import(
                    /* webpackChunkName: "company-teams" */ '@/pages/companies/teams/Edit.vue'
                ),
            props: true,
            meta: {
                ...meta,
                breadcrumbs: [breadCrumbs.companyEdit, breadCrumbs.teamsList],
            },
        },
    ],
}

export default [
    {
        path: '/companies',
        component: passthroughRender,
        meta,
        children: [
            {
                path: '',
                name: routeType.COMPANY_LIST,
                component: () =>
                    import(
                        /* webpackChunkName: "company-list" */ '@/pages/companies/List.vue'
                    ),
                meta: {
                    ...meta,
                    roles: [ROLE_ADMIN],
                },
            },
            {
                path: 'create',
                name: routeType.COMPANY_CREATE,
                component: () =>
                    import(
                        /* webpackChunkName: "company-edit" */ '@/pages/companies/Create.vue'
                    ),
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.companyList],
                },
            },
            {
                path: ':companyId',
                component: passthroughRender,
                props: true,
                meta: {
                    ...meta,
                    breadcrumbs: [breadCrumbs.companyList],
                },
                children: [
                    {
                        path: '',
                        name: routeType.COMPANY_EDIT,
                        component: () =>
                            import(
                                /* webpackChunkName: "company-edit" */ '@/pages/companies/Edit.vue'
                            ),
                        props: true,
                        meta,
                    },
                    companyAddress,
                    companyAllowance,
                    companyCloudcanteen,
                    companyTeams,
                    {
                        path: 'employees',
                        name: routeType.COMPANY_EMPLOYEES,
                        component: () =>
                            import(
                                /* webpackChunkName: "company-employees" */ '@/pages/companies/employees/List.vue'
                            ),
                        props: true,
                        meta,
                    },
                    {
                        path: 'invoices',
                        name: routeType.COMPANY_INVOICES,
                        component: () =>
                            import(
                                /* webpackChunkName: "company-invoices" */ '@/pages/companies/invoices/List.vue'
                            ),
                        props: true,
                        meta,
                    },
                ],
            },
        ],
    },

    {
        path: '/company',
        name: routeType.COMPANY_SELF_EDIT,
        component: () =>
            import(
                /* webpackChunkName: "company-edit" */ '@/pages/companies/SelfEdit.vue'
            ),
        meta: {
            ...meta,
            roles: [ROLE_COMPANY],
        },
    },
]
