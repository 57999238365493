import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import canteenType from '@/store/type/canteenType'
import apiClient from '@/api/ApiClient'
import Store from '@/store/store'

class CloudCanteenApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/orders/canteen'
    }

    get(restaurantId, canteenIdentifier) {
        return ResponseHandler.handle(
            apiClient
                .get(this.getItemIri(restaurantId, canteenIdentifier))
                .then((data) => {
                    Store.commit(canteenType.mutations.SET_CANTEEN, data.data)
                    return data
                })
        )
    }

    list() {
        return ResponseHandler.handle(
            apiClient.get(this.getBaseUrl()).then((data) => {
                Store.commit(canteenType.mutations.SET_CANTEEN_LIST, data.data)
                return data
            })
        )
    }
}

export default new CloudCanteenApiClient()
