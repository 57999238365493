import { mapGetters } from 'vuex'
import orderType from '@/store/type/orderType'

export default {
    data() {
        return {
            active: 0,
            width: 0,
        }
    },

    computed: {
        ...mapGetters({
            loadingApprove: orderType.loaders.APPROVE_ORDER_LOADING,
            loadingConfirm: orderType.loaders.CONFIRM_ORDER_LOADING,
            loadingList: orderType.loaders.GET_NEW_ORDERS_LOADING,
            loadingOrder: orderType.loaders.GET_ORDER_LOADING,

            newOrdersList: orderType.getters.NEW_ORDERS_LIST,
            approvedOrdersList: orderType.getters.APPROVED_ORDERS_LIST,

            confirmOpen: orderType.getters.CONFIRM_OPEN,
            approveOpen: orderType.getters.APPROVE_OPEN,

            // restaurantId: restaurantType.getters.RESTAURANT_ID,
            //isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
    },

    mounted() {
        this.width = getComputedStyle(document.body).getPropertyValue(
            '--restaurant-admin-modal-max-width'
        )
    },
}
