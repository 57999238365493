<script>
import ActiveOrder from '@/components/pages/restaurantAdmin/ActiveOrder'
import ApproveButton from '@/components/pages/restaurantAdmin/confirm/ApproveButton'
import ConfirmMixin from '@/components/mixins/ConfirmMixin'
import orderType from '@/store/type/orderType'

export default {
    name: 'ApproveOrder',
    components: {
        ApproveButton,
        ActiveOrder,
    },
    mixins: [ConfirmMixin],
    computed: {
        openConfirmation() {
            return this.newOrdersList?.length
        },
        needNavigation() {
            return this.openConfirmation > 1
        },
        isAsap() {
            return this.newOrdersList[this.active]?.isAsap
        },
        classes() {
            return `incoming-order ${this.isAsap ? '' : 'scheduled-order'}`
        },
        preparationTime: {
            get() {
                return this.newOrdersList[this.active]?.deliveryJob
                    ?.preparationTime
            },
            set(val) {
                this.$set(
                    this.newOrdersList[this.active].deliveryJob,
                    'preparationTime',
                    val
                )
            },
        },
    },
    watch: {
        openConfirmation: {
            immediate: true,
            handler(openConfirmation, numberOfOrdersBefore) {
                if (
                    (this.confirmOpen &&
                        openConfirmation > 1 &&
                        openConfirmation > numberOfOrdersBefore) ||
                    (this.confirmOpen &&
                        openConfirmation === 1 &&
                        numberOfOrdersBefore === 0)
                ) {
                    this.$emit('order')
                }
                this.$store.commit(
                    orderType.mutations.SET_INCOMING_CONFIRM_STATE,
                    Boolean(openConfirmation)
                )
                this.$store.commit(
                    orderType.mutations.SET_CONFIRM_STATE,
                    Boolean(openConfirmation)
                )
            },
        },
    },
    methods: {
        next() {
            this.$refs.carousel.next()
            if (!this.newOrdersList[this.active].meals)
                this.$store.dispatch(orderType.actions.GET_ORDER, [
                    this.newOrdersList[this.active].id,
                    orderType.mutations.UPDATE_NEW_ORDERS_LIST,
                ])
        },
        prev() {
            this.$refs.carousel.prev()
            if (!this.newOrdersList[this.active].meals)
                this.$store.dispatch(orderType.actions.GET_ORDER, [
                    this.newOrdersList[this.active].id,
                    orderType.mutations.UPDATE_NEW_ORDERS_LIST,
                ])
        },
    },
}
</script>

<template lang="pug">
    v-dialog(:value="openConfirmation" :content-class="classes" :max-width="width" overlay-color="black" :overlay-opacity=".5" persistent light)
        v-card.px-3(:loading="loadingApprove" height="100%" light)
            v-carousel(v-model="active" height="100%" ref="carousel" :show-arrows="false" hide-delimiters light hide-delimiter-background)
                v-carousel-item(v-for="(order, index) in newOrdersList" :key="`hasIncomingOrder-${index}`")
                    v-container.pt-3.mh-100.h-100.d-flex.flex-column.overflow-y-auto(fluid)
                        active-order(:order="order")
            approve-button(
                v-model="preparationTime"
                :order="newOrdersList[active]"
                :index="active"
                :disabled="loadingApprove || loadingList")
                .total(slot="total") {{active + 1}}/{{newOrdersList.length}}
        v-fade-transition
            .navigation-wrapper(v-if="needNavigation")
                v-btn(@click="prev" color="g-f7" icon light plain)
                    v-icon arrow_back
                v-btn(@click="next" color="g-f7" icon light plain)
                    v-icon arrow_forward


</template>
