<script>
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import configType from '@/store/type/configType'
import HeaderBar from '@/components/layout/components/HeaderBar'
import FooterBar from '@/components/layout/components/FooterBar'
import Sidebar from './components/Sidebar'
import MainContent from '@/components/layout/components/MainContent'

export default {
    name: 'OrditAuthenticatedLayout',
    components: {
        MainContent,
        FooterBar,
        HeaderBar,
        Sidebar,
    },
    data: () => ({
        drawer: true,
        absolute: false,
    }),
    computed: {
        ...mapGetters({
            isInlined: configType.getters.INLINED,
            isLoggedIn: authType.getters.IS_LOGGED_IN,
        }),
    },
    created() {
        this.$set(this, 'drawer', this.$vuetify.breakpoint.mdAndUp)
    },
}
</script>
<template lang="pug">
    .admin-auth(v-if='isLoggedIn')
        transition(name='fade' mode='out-in' appear)
            header-bar(v-model="drawer" :absolute="absolute")
        transition(name='fade' mode='out-in' appear)
            sidebar(v-if='!isInlined' v-model='drawer')
        main-content(:is-inline="isInlined")
            slot
        transition(name='fade' mode='out-in' appear)
            footer-bar


</template>
