export default {
    getters: {
        INLINED: 'config_inlined',
        CURRENCY_CODE: 'config_currency_code',
        CURRENCY_LOCALE: 'config_currency_locale',
        CONFIG_IS_LOADED: 'config_config_is_loaded',
        TRANSLATIONS_ARE_LOADED: 'config_translations_are_loaded',
        LOCALES: 'config_locales',
        CURRENT_LOCALE: 'config_current_locale',
        DEFAULT_LOCALE: 'config_default_locale',
        LOCALE_CONFIGS: 'config_local_configs',
        GOOGLE_MAPS_API_KEY: 'config_google_maps_api_key',
        GOOGLE_MAPS_DEFAULT_CENTER: 'config_google_maps_default_center',
        COUNTRY: 'config_country',
        LATEST_TERMS_VERSION: 'config_terms',
        LATEST_PRIVACY_VERSION: 'config_privacy',
        NOTIFICATION_PERMISSION_GRANTED: 'notification_permission_granted',
    },
    mutations: {
        INLINED: 'config_inlined',
        CURRENCY_CODE: 'config_currency_code',
        CURRENCY_LOCALE: 'config_currency_locale',
        CONFIG_IS_LOADED: 'config_config_is_loaded',
        TRANSLATIONS_ARE_LOADED: 'config_translations_are_loaded',
        LOCALES: 'config_locales',
        CURRENT_LOCALE: 'config_current_locale',
        DEFAULT_LOCALE: 'config_default_locale',
        LOCALE_CONFIGS: 'config_local_configs',
        GOOGLE_MAPS_API_KEY: 'config_google_maps_api_key',
        GOOGLE_MAPS_DEFAULT_CENTER: 'config_google_maps_default_center',
        COUNTRY: 'config_country',
        LATEST_TERMS_VERSION: 'config_terms',
        LATEST_PRIVACY_VERSION: 'config_privacy',
        SET_NOTIFICATION_PERMISSION: 'set_notification_permission',
    },
    actions: {
        INIT: 'initialize',
        SET_INLINED: 'config_set_inlined',
        LOAD_CONFIGS: 'config_load_configs',
        LOAD_TRANSLATIONS: 'config_load_translations',
        SET_NOTIFICATION_PERMISSION: 'set_notification_permission',
    },
}
