import cloneDeep from 'lodash/cloneDeep'

class OrderFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        if (!formData['refund']) formData['refund'] = {}

        return formData
    }
}

export default new OrderFormMapper()
