<script>
export default {
    name: 'DropOff',
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        deliveries: Boolean,
    },
    computed: {
        isCanteen() {
            return !!this.item.cloudcanteen || !!this.item.residentialcanteen
        },
        deliveryJob() {
            return this.item.deliveryJob || {}
        },
        isAsap() {
            return this.deliveries
                ? this.deliveryJob?.orderIsAsap
                : this.item?.isAsap
        },
    },
}
</script>

<template lang="pug">
    span(v-if='deliveryJob.orderDropoffAt')
        template(v-if='isAsap')
            | {{ deliveryJob.orderDropoffAt | transformDate }}
        template(v-else)
            | {{ deliveryJob.orderDropoffAt | transformDate( -30 ) }} -
            | {{ deliveryJob.orderDropoffAt | transformDate( 0, true ) }}
    span(v-else-if="item.requiredDeliveryTime")
        template(v-if="isCanteen")
            | {{ item.requiredDeliveryTime | transformDate }} -
            | {{ item.requiredDeliveryTime | transformDate( 30, true ) }}
        template(v-else) {{ item.requiredDeliveryTime | transformDate }}
    span(v-else) -
</template>
