<script>
import MobileNumber from '@/components/pages/restaurantAdmin/MobileNumber'
import OrderItem from '@/components/pages/restaurantAdmin/OrderItem'
import DeliveryDetails from '@/components/pages/restaurantAdmin/DeliveryDetails'
import restaurantType from '@/store/type/restaurantType'
import { mapGetters } from 'vuex'
import DeliveryFee from '@/components/pages/restaurantAdmin/DeliveryFee'
import PrintReceipt from '@/components/pages/restaurantAdmin/PrintReceipt'
import PrintLabel from '@/components/pages/restaurantAdmin/PrintLabel'
import PrintSummary from '@/components/pages/restaurantAdmin/PrintSummary'
import configType from '@/store/type/configType'
import authType from '@/store/type/authType'
import PaidBy from '@/components/pages/restaurantAdmin/PaidBy'
import Discount from '@/components/pages/restaurantAdmin/Discount'
import orderType from '@/store/type/orderType'

export default {
    name: 'ActiveOrder',
    components: {
        Discount,
        PaidBy,
        PrintReceipt,
        PrintLabel,
        PrintSummary,
        DeliveryFee,
        DeliveryDetails,
        OrderItem,
        MobileNumber,
    },
    props: {
        order: null,
        firstOrder: null,
        confirmToPrepare: Boolean,
        main: Boolean,
    },
    computed: {
        ...mapGetters({
            activeOrder: orderType.getters.ACTIVE_ORDER,
            loadingOrder: orderType.loaders.GET_ORDER_LOADING,
            locale: configType.getters.CURRENT_LOCALE,
            isMultiRestaurantAdmin: authType.getters.IS_MULTI_RESTAURANT_ADMIN,
            restaurant: restaurantType.getters.ACTIVE_RESTAURANT,
        }),
        orderName() {
            return this.locale === 'hu'
                ? `${this.innerOrder?.userProfile?.lastName} ${this.innerOrder?.userProfile?.firstName}`
                : `${this.innerOrder?.userProfile?.firstName} ${this.innerOrder?.userProfile?.lastName}`
        },
        innerOrder() {
            return this.main ? this.activeOrder : this.order
        },
        isNew() {
            return this.innerOrder?.status === 'new'
        },
        isAsap() {
            return this.innerOrder?.isAsap
        },
        isCanteen() {
            return !this.innerOrder?.userProfile
        },
        isScheduled() {
            return (
                !this.innerOrder?.preparationConfirmed &&
                !this.isAsap &&
                !this.isCanteen
            )
        },
        isCashPayment() {
            return (
                this.innerOrder?.paymentMethod === 'cash' &&
                Number(this.innerOrder?.priceSumUser) > 0
            )
        },
        itemsCount() {
            return this.innerOrder?.meals?.reduce(
                (a, b) => Number(a) + Number(b.quantity),
                0
            )
        },
        deliveryByYou() {
            return !(
                this.innerOrder?.deliveryJob &&
                this.innerOrder?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                if (id && this.main) this.getOrder(id)
            },
        },
        activeOrder: {
            handler(order) {
                if (!order && this.main) this.resetOrder()
            },
        },
        firstOrder: {
            immediate: true,
            handler(firstOrder) {
                if (
                    !this.activeOrder &&
                    firstOrder &&
                    firstOrder.id.toString() !==
                        this.$route.params.id?.toString() &&
                    this.main
                )
                    this.$router.replace({ params: { id: firstOrder.id } })
            },
        },
    },
    methods: {
        getOrder(id) {
            if (!this.activeOrder || this.activeOrder.id !== id)
                this.$store.dispatch(orderType.actions.GET_ORDER, [id, false])
        },
        resetOrder() {
            this.$nextTick(() => {
                if (this.$route.params.id) {
                    this.$router.replace({ params: { id: undefined } })
                } else if (this.firstOrder) {
                    this.$router.replace({ params: { id: this.firstOrder.id } })
                }
            })
        },
    },
}
</script>

<template lang="pug">
v-fade-transition(mode="out-in")
    v-progress-circular.mx-auto.d-block(v-if="loadingOrder" :size="50" color="primary" indeterminate)
    v-card.active-order(v-else-if="innerOrder && (!main || innerOrder.isActiveForRestaurant)" :elevation="0")
        v-card-text(:class="{'p-0': !main}")
            v-row(dense).mb-3
                v-col
                    .d-flex.flex-column.align-start
                        .text-h6.font-weight-bold {{'#'}}{{ innerOrder.identifierForRestaurant }}
                            v-chip.font-weight-bold.ml-2(v-if="isScheduled" color="red" label x-small dark) {{ $t('orders.scheduled') }}
                        .text-body-2.g-73--text(v-if="!isCanteen") {{ orderName }}
                        mobile-number(v-if="!isCanteen" :number="innerOrder.phone")
                v-col(cols="auto")
                    delivery-details(:order="innerOrder")
                v-col
                    .d-flex.flex-column.align-end
                        .text-h6.font-weight-bold
                            template(v-if="deliveryByYou && !isCanteen") {{ innerOrder.priceSum | currency }}
                            template(v-else) {{ innerOrder.priceSumItems | currency }}
                        .text-body-2.g-73--text {{ $tc('orders.items', itemsCount, { count: itemsCount}) }}

            v-divider(v-if="isMultiRestaurantAdmin" light)
            .text-h6.font-weight-bold.text-center.my-3(v-if="isMultiRestaurantAdmin") {{ innerOrder.restaurantName }}
            v-divider.mb-4(v-if="deliveryByYou && !isCanteen" light)
            v-alert(v-if="deliveryByYou && !isCanteen" color="blue-1")
                strong {{ innerOrder.deliveryFullAddress }}
                br
                span.text-body-2.g-73--text {{ innerOrder.deliverySubAddress }}
            v-divider.mb-4(v-if="isCashPayment || innerOrder.comment" light)
            .text-h6.primary--text.d-flex.align-center.justify-center.my-3(v-if="isCashPayment")
                v-icon.mr-2(color="primary") local_atm
                | {{ $t('orders.cash_payment') }} {{ innerOrder.priceSumUser | currency }}
            v-alert(v-if="innerOrder.comment" color="yellow-1")
                span {{innerOrder.comment}}
            .text-h6.my-3(v-if="isCanteen") {{ $tc('labels.prepare_portions', itemsCount, { n: itemsCount}) }}
            transition(name="fade" mode="out-in")
                v-progress-circular.d-flex.mx-auto(v-if="loadingOrder" size="80" width="6" color="success" indeterminate)
                .order-item-wrapper(v-else)
                    order-item(v-for="(meal, index) in innerOrder.meals" :cc="isCanteen" :model="meal" :key="`orderMeal-${index}`")
                    delivery-fee(v-if="deliveryByYou && !isCanteen" :order="innerOrder")
                    discount(v-if="deliveryByYou && !isCanteen" :order="innerOrder")
                    paid-by(v-if="deliveryByYou && !isCanteen" :order="innerOrder")
            .d-flex.justify-end.mt-3
                print-receipt(v-if="!isNew && !confirmToPrepare && !isCanteen" :order="innerOrder")
                print-summary(v-if="!isNew && !confirmToPrepare && isCanteen" :order-identifier="innerOrder.identifier")
                print-label.ml-3(v-if="!isNew && !confirmToPrepare && isCanteen" :order="innerOrder")
</template>

<style scoped lang="scss">
.active-order {
    max-width: var(--manage-order-width);
}
</style>
