<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import IdFromIri from '@/services/IdFromIri'
import orderType from '@/store/type/orderType'
import printJS from 'print-js'
import { mapGetters } from 'vuex'

export default {
    name: 'ApproveButton',
    props: {
        order: Object,
        value: Number,
        index: Number,
        disabled: Boolean,
    },
    data() {
        return {
            api: RestApiType.ORDERS,
        }
    },
    computed: {
        ...mapGetters({
            loadingApprove: orderType.loaders.APPROVE_ORDER_LOADING,
        }),
        id() {
            return this.order?.id
        },
        restaurantId() {
            return IdFromIri.getId(this.order?.restaurant)
        },
        deliveryByYou() {
            return !(
                this.order?.deliveryJob &&
                this.order?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },
        isAsap() {
            return this.order.isAsap
        },
        isCanteen() {
            return !this.order?.userProfile
        },
        ccId() {
            return (
                this.order?.cloudcanteen?.identifier ||
                this.order?.residentialcanteen?.identifier
            )
        },
        orderId() {
            return this.order?.identifier
        },
        color() {
            return this.isAsap ? 'primary' : 'warning'
        },
    },

    methods: {
        decrease() {
            if (this.value > 10 && !(this.loadingApprove || this.disabled))
                this.$emit('input', this.value - 5)
        },
        increase() {
            this.$emit('input', this.value + 5)
        },
        confirm() {
            this.$store
                .dispatch(orderType.actions.APPROVE_ORDER, [
                    this.id,
                    this.value,
                ])
                .then(() => {
                    this.$store
                        .dispatch(orderType.actions.GET_NEW_ORDERS)
                        .then((list) => {
                            if (list?.length) {
                                this.$store.dispatch(
                                    orderType.actions.GET_ORDER,
                                    [
                                        list[this.index].id,
                                        orderType.mutations
                                            .UPDATE_NEW_ORDERS_LIST,
                                    ]
                                )
                            }
                        })
                    this.$store.dispatch(orderType.actions.GET_ORDERS)
                })
        },
        printLabel() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadLabel(this.restaurantId, this.ccId)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
        printSummary() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadSummary(this.orderId)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
    },
}
</script>

<template lang="pug">
.confirm-orders(v-if="order"  ref="confirmOrders" :class="isCanteen ? 'is-cc' : ''")
    v-row(v-if="isCanteen")
        v-col.mb-0(cols="12" md="6")
            v-btn(color="info" @click="printSummary" :disabled="disabled" block) {{ $t('orders.summary_print') }}
        v-col.mb-0(cols="12" md="6")
            v-btn(color="info" @click="printLabel" :disabled="disabled" block) {{ $t('orders.print_labels') }}
    v-row.mt-0
        v-col.mb-0(cols="12")
            v-btn(:color="color" @click="confirm" :disabled="disabled" block) {{ $t('orders.confirm_order') }}
    slot(name="total")
</template>

<style scoped lang="scss"></style>
