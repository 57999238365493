import Event from '@/mixins/Event'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import throttle from 'lodash/throttle'
export default {
    mixins: [Event],
    data() {
        return {
            channelOrder: null,
            channelDeliveryJobs: null,
            logToConsole: false,
            counter: 0,
            throttleOrder: null,
        }
    },
    mounted() {
        this.throttleOrder = throttle(this.updateList, 5000)

        this.channelOrder = this.pusher.subscribe('orders')
        this.channelDeliveryJobs = this.pusher.subscribe('delivery-jobs')

        this.channelOrder.bind('create-order', this.createOrder)
        this.channelOrder.bind('update-order', this.updateOrder)
        this.channelDeliveryJobs.bind(
            'create-delivery-job',
            this.createDelivery
        )
        this.channelDeliveryJobs.bind(
            'update-delivery-job',
            this.updateDelivery
        )
    },
    beforeDestroy() {
        if (this.pusher) {
            // this.channelOrder.unbind()
            // this.channelDeliveryJobs.unbind()
            // this.pusher.unsubscribe('orders')
            // this.pusher.unsubscribe('delivery-jobs')
            this.pusher.disconnect()
        }
    },
    methods: {
        createDelivery(data) {
            if (data) {
                let orderIds = data.data?.data_order_ids?.new || '[]'
                orderIds = JSON.parse(orderIds)
                orderIds?.forEach((id) => {
                    let itemKey = this.findOrder(id)
                    this.updateItem(
                        data.data,
                        itemKey,
                        this.mapDeliveryJob,
                        'deliveryJob'
                    )
                })
            }
        },
        updateDelivery(data) {
            if (data) {
                const itemKeys = this.findOrders(data.id, 'deliveryJob')
                itemKeys.forEach((itemKey) => {
                    this.updateItem(
                        data.data,
                        itemKey,
                        this.mapDeliveryJob,
                        'deliveryJob'
                    )
                })
            }
        },
        createOrder(data) {
            if (data) {
                if (this.filtersOn) {
                    this.throttleOrder()
                } else {
                    this.getOrder(data.id).then((data) => {
                        this.$refs.iterator.items.unshift(data)
                    })
                }
            }
            // if (data) {
            //     const orderId = data.id
            //     data.data.created_at.new = this.$date(
            //         data.data.created_at.new
            //     ).format()
            //     data.data.restaurant_id.new = `/api/v1/restaurants/${data.data.restaurant_id.new}`
            //     data.data.user_profile_id.new = `/api/v1/users/${data.data.user_profile_id.new}`
            //     data.data.company_id.new = `/api/v1/companies/${data.data.company_id.new}`
            //
            //     let ize = this.objectMapper(
            //         {
            //             '@id': `/api/v1/orders/${orderId}`,
            //             id: orderId,
            //         },
            //         data.data,
            //         this.mapOrder
            //     )
            //     this.$refs.iterator.items.unshift(ize)
            // }
        },
        updateOrder(data) {
            const itemKey = this.findOrder(data.id)
            this.updateItem(data.data, itemKey, this.mapOrder)
        },
        getOrder(orderId) {
            return new Promise((resolve, reject) => {
                RestApiCollection.get(RestApiType.ORDERS)
                    .get(orderId)
                    .then((data) => {
                        const entity = MapperCollection.get(
                            MapperType.ORDER_FORM
                        ).fromEntity(data)
                        resolve(entity)
                    })
                    .catch((reason) => {
                        reject(reason)
                    })
            })
        },
    },
}
