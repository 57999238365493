<script>
export default {
    name: 'MobileNumber',
    props: {
        number: null,
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        toggle() {
            this.show = !this.show
        },
    },
}
</script>

<template lang="pug">
.mobile-number.c-pointer.g-cc--text(@click="toggle")
    v-fade-transition()
        small(v-if="show") {{ number }}
        small(v-else) {{ $t('actions.show_mobile') }}
</template>

<style scoped lang="scss"></style>
