import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import ResponseHandler from '@/api/response/ResponseHandler'
import apiClient from '@/api/ApiClient'

class AuditLogApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/api/v1/auditLog'
    }
    get(id, entity, query) {
        return ResponseHandler.handle(
            apiClient.get(`${this.getBaseUrl()}/${entity}/${id}`, {
                params: query,
            })
        )
    }
}

export default new AuditLogApiClient()
