<template>
    <v-btn
        color="secondary"
        class="black--text"
        depressed
        :block="block"
        @click="$emit('cancel')"
    >
        {{ label }}
    </v-btn>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: null,
        },
        block: Boolean,
    },
    computed: {
        label() {
            return this.text ?? this.$t('actions.cancel')
        },
    },
}
</script>
