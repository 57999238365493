import cloneDeep from 'lodash/cloneDeep'

class CompaniesFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        if (entity.hideAllRestaurants) {
            entity.hiddenRestaurants = []
        } else {
            entity.hideAllExceptions = []
        }

        if (typeof entity.deliveryCompletedTemplate == 'undefined') {
            entity.deliveryCompletedTemplate = null
        }

        if (
            typeof entity.serviceFeePercent === 'number' &&
            isFinite(entity.serviceFeePercent)
        ) {
            entity.serviceFeePercent = entity.serviceFeePercent.toString()
        }

        if (!entity.orderConfirmationEmail) entity.orderConfirmationEmail = null
        if (!entity.deliveryCompletedEmail) entity.deliveryCompletedEmail = null
        if (!entity.deliveryDriverDeliveringEmail)
            entity.deliveryDriverDeliveringEmail = null

        if (entity.ccPartnerRelations) {
            for (let relation of entity.ccPartnerRelations) {
                relation.deliveryTime = parseInt(relation.deliveryTime)
                if (typeof relation['@id'] === 'number') {
                    delete relation['@id']
                }
            }
        }

        entity.deliveryPriceOverride = entity.deliveryPriceOverride
            ? parseFloat(entity.deliveryPriceOverride)
            : null

        return entity
    }

    fromEntity(entity) {
        const values = cloneDeep(entity)

        if (values.hideAllRestaurants) {
            values.hiddenRestaurants = []
        } else {
            values.hideAllExceptions = []
        }

        if (values.billingInfo === null) {
            values.billingInfo = {}
        }

        return values
    }
}

export default new CompaniesFormMapper()
