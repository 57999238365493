<script>
export default {
    name: 'Back',
    methods: {
        back() {
            this.$router.go(-1)
        },
    },
}
</script>

<template lang="pug">
v-btn(@click="back" fab color="g-cc")
    v-icon arrow_back

</template>

<style scoped lang="scss"></style>
