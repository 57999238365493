import cloneDeep from 'lodash/cloneDeep'
import PeriodMapper from '@/services/mapper/PeriodMapper'
// import store from '@/store/store'
// import configType from '@/store/type/configType'
import RestaurantSoftwareType from '@/enum/RestaurantSoftwareType'
import CloneDeep from 'lodash/cloneDeep'

class RestaurantFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        // convert to ids
        if (entity['coverImageMedia'] && entity['coverImageMedia']['@id']) {
            entity['coverImageMedia'] = entity['coverImageMedia']['@id']
        }

        if (entity['cardImageMedia'] && entity['cardImageMedia']['@id']) {
            entity['cardImageMedia'] = entity['cardImageMedia']['@id']
        }

        // opening days
        entity['openingDays'] = PeriodMapper.toEntity(entity['openingDays'])

        entity['specialClosures'].forEach((holiday) => {
            entity['openingDays'].push(holiday)
        })

        delete entity['specialClosures']

        // country settings
        // entity['country'] = store.getters[configType.getters.COUNTRY]

        // software settings
        if (!entity['softwareSettings']) {
            entity['softwareSettings'] = []
        }

        entity['softwareSettings'] = entity['softwareSettings'].filter(
            (softwareSetting) => {
                return softwareSetting.software !== RestaurantSoftwareType.ordit
            }
        )

        if (
            !entity['deliverySetting'].deliveryRules.length &&
            typeof entity['deliverySetting'].defaultPreparationTime !== 'number'
        ) {
            entity['deliverySetting'] = null
        }

        if (entity.deliverySetting?.deliveryRules?.length) {
            entity.deliverySetting.deliveryRules.forEach((rule) => {
                if (!rule.distanceTo) {
                    rule.distanceTo = null
                }
            })
        }

        if (
            Object.keys(entity['pickupInformation']).filter((key) =>
                /^[^@]\w+$/.test(key) ? entity['pickupInformation'][key] : false
            ).length === 0
        ) {
            entity['pickupInformation'] = null
        }

        if (entity['orditSoftwareSetting']) {
            entity['softwareSettings'].push(entity['orditSoftwareSetting'])
        }

        delete entity['orditSoftwareSetting']

        entity['softwareSettings'].forEach((softwareSetting) => {
            if (!softwareSetting || !softwareSetting['softwareLimits']) {
                return
            }

            softwareSetting['softwareLimits'].forEach((softwareLimit) => {
                softwareLimit['minOrder'] = parseFloat(
                    softwareLimit['minOrder']
                )
                softwareLimit['maxOrder'] = parseFloat(
                    softwareLimit['maxOrder']
                )
                softwareLimit['timeBeforeInMinute'] = parseInt(
                    softwareLimit['timeBeforeInMinute']
                )
            })
        })

        if (entity.areas?.length) {
            entity.areas.forEach((restArea) => {
                if (restArea.area && restArea.area.length) {
                    restArea.area[0].push(restArea.area[0][0])
                }
            })
        }

        if (!isNaN(entity['billingInfo']['cardTransactionFee'])) {
            entity['billingInfo']['cardTransactionFee'] = (
                entity['billingInfo']['cardTransactionFee'] / 100
            ).toString()
        }
        if (!isNaN(entity['billingInfo']['commOnDelivery'])) {
            entity['billingInfo']['commOnDelivery'] = (
                entity['billingInfo']['commOnDelivery'] / 100
            ).toString()
        }
        if (!isNaN(entity['billingInfo']['commOnFood'])) {
            entity['billingInfo']['commOnFood'] = (
                entity['billingInfo']['commOnFood'] / 100
            ).toString()
        }

        entity['priceCategory'] = 'average'
        entity['isCatering'] = null
        entity['isVisibleForCompany'] = true
        entity['isVisibleForPublic'] = true
        entity['hiddenCompanies'] = []
        entity['preorderDayUntil'] = 30
        entity['billingInfo']['pickupAddress'] = entity['location']

        if (!entity.deliverooUrl) {
            entity.deliverooUrl = null
        }

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        // openingDays
        const openingDays = []
        const specialClosures = []

        formData['openingDays'].forEach((value) => {
            if (value.date == null) {
                openingDays.push(value)
            } else {
                specialClosures.push(value)
            }
        })

        specialClosures.forEach((day) => {
            const intervals = []
            if (day.timeIntervals && Array.isArray(day.timeIntervals)) {
                day.timeIntervals.forEach((hour) => {
                    intervals.push({
                        openTime: PeriodMapper.getHour(hour.openTime),
                        closeTime: PeriodMapper.getHour(hour.closeTime),
                    })
                })
            }

            day.timeIntervals = intervals
            day.date = PeriodMapper.getDate(day.date)
        })

        formData['openingDays'] = openingDays
        formData['specialClosures'] = specialClosures

        formData['openingDays'] = PeriodMapper.fromEntity(
            formData['openingDays']
        )

        if (!formData['pickupInformation']) {
            formData['pickupInformation'] = {}
        }

        if (!formData['billingInfo']) {
            formData['billingInfo'] = {}
        }

        if (!formData['deliverySetting']) {
            formData['deliverySetting'] = {
                defaultPreparationTime: null,
            }
            if (!formData['deliverySetting']['deliveryRules']) {
                formData['deliverySetting']['deliveryRules'] = []
            }
        }

        if (!formData['timezoneRegion']) {
            formData['timezoneRegion'] = null
        }

        if (formData['preorderBorderTime']) {
            formData['preorderBorderTime'] = formData[
                'preorderBorderTime'
            ].substr(11, 5)
        }

        // translation obj
        if (
            !formData['translations'] ||
            Array.isArray(formData['translations'])
        ) {
            formData['translations'] = {
                en: {
                    badge: '',
                    banner: '',
                    description: '',
                    search: '',
                },
                hu: {
                    badge: '',
                    banner: '',
                    description: '',
                    search: '',
                },
            }
        }

        // software settings
        if (formData['softwareSettings']) {
            let orditSoftware = {}
            formData['softwareSettings'].forEach((softwareSetting) => {
                if (softwareSetting.software === RestaurantSoftwareType.ordit) {
                    orditSoftware = CloneDeep(softwareSetting)
                }
            })

            formData['orditSoftwareSetting'] = orditSoftware
        }

        formData.areas.forEach((restArea) => {
            if (restArea.area) {
                restArea.area[0].pop()
            }
        })

        if (formData['billingInfo']['cardTransactionFee']) {
            formData['billingInfo']['cardTransactionFee'] = parseFloat(
                (formData['billingInfo']['cardTransactionFee'] * 100).toFixed(2)
            )
        }
        if (formData['billingInfo']['commOnDelivery']) {
            formData['billingInfo']['commOnDelivery'] = parseFloat(
                (formData['billingInfo']['commOnDelivery'] * 100).toFixed(2)
            )
        }
        if (formData['billingInfo']['commOnFood']) {
            formData['billingInfo']['commOnFood'] = parseFloat(
                (formData['billingInfo']['commOnFood'] * 100).toFixed(2)
            )
        }

        if (!formData['restaurantDiscount']) {
            formData['restaurantDiscount'] = {
                discountPriceEnabled: false,
                discountPriceOnlyForCanteen: false,
            }
        }

        return formData
    }
}

export default new RestaurantFormMapper()
