<script>
import RestApiCollection from '@/api/RestApiCollection'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import routeType from '@/router/routeType'
import Notification from '@/services/Notification/Notification'
import RestApiType from '@/api/RestApiType'
import EditButton from '@/components/table/buttons/EditButton'
export default {
    name: 'TableActions',
    components: {
        ConfirmModal,
        EditButton,
    },
    props: {
        item: Object,
        api: String,
        updateTableItems: Function,
    },
    data() {
        return {
            confirmDeliverSendOutModal: false,
            confirmCancelOrderModal: false,
            deliverySendOutItem: null,
            cancelOrderItem: null,
            processing: false,
            routeType: routeType,
        }
    },
    computed: {
        isCanteen() {
            return (
                Boolean(this.item.cloudcanteen) ||
                Boolean(this.item.residentialcanteen)
            )
        },
        canConfirm() {
            return (
                this.item.status !== 'approved' &&
                this.item.status !== 'init-payment' &&
                this.item.isDeliveryProcessed &&
                !this.isCanteen
            )
        },
        canCancel() {
            return (
                this.item.status !== 'cancelled' &&
                this.item.status !== 'cancelled-after-approval' &&
                this.item.status !== 'init-payment' &&
                this.item.isDeliveryProcessed &&
                !this.isCanteen
            )
        },
        canSend() {
            return (
                this.item.deliveryJob &&
                this.item.deliveryJob.id &&
                this.item.deliveryJob.status === 'initialized' &&
                this.item.isDeliveryProcessed &&
                !this.isCanteen
            )
        },
    },
    methods: {
        confirmOrder(id) {
            RestApiCollection.get(this.api).approve(id)
        },

        cancelOrder() {
            RestApiCollection.get(this.api)
                .cancel(this.cancelOrderItem.id)
                .then(() => {
                    this.confirmCancelOrderModal = false
                })
                .then(() => {
                    Notification.success(
                        this.cancelOrderItem.identifier,
                        this.$t('notifications.entity_deleted.message', {
                            name: this.cancelOrderItem.identifier,
                        })
                    )
                })
                .finally(() => {
                    this.cancelOrderItem = null
                })
        },
        getDeliveryListLink(item, tab = 0) {
            return {
                name: routeType.ORDER_EDIT,
                params: {
                    id: item.id,
                },
                query: {
                    tab: tab,
                },
            }
        },

        resendMail(item) {
            RestApiCollection.get(this.api)
                .mailSend(item.id)
                .then(() => {
                    Notification.success(
                        item.identifier,
                        this.$t('notifications.order_mail_resent.message')
                    )
                })
        },

        cancelOrderModal(item) {
            this.confirmCancelOrderModal = true
            this.cancelOrderItem = item
        },

        deliverySendOutModal(item) {
            this.confirmDeliverSendOutModal = true
            this.deliverySendOutItem = item.deliveryJob.id
        },

        setDeliverySendOut() {
            this.processing = true
            RestApiCollection.get(RestApiType.DELIVERIES)
                .sendOutDeliveryJob(this.deliverySendOutItem)
                .then(() => {
                    Notification.success(
                        this.deliverySendOutItem,
                        this.$t('notifications.order_delivery_sent_out.message')
                    ),
                        (this.confirmDeliverSendOutModal = false)
                    this.updateTableItems()
                })
                .catch((reason) => {
                    Notification.error('order send out error', reason)
                })
                .finally(() => {
                    this.processing = false
                })
        },

        cancelDeliverySendOut() {
            this.confirmDeliverSendOutModal = false
            this.deliverySendOutItem = null
        },

        cancelOrderCancel() {
            this.confirmCancelOrderModal = false
            this.cancelOrderItem = null
        },
    },
}
</script>

<template lang="pug">
.d-flex
    edit-button(
        v-if="canConfirm"
        :tooltip="$t('labels.approve_order')"
        @execute='confirmOrder(item.id)'
        icon='check_circle' )
    edit-button(
        v-if="canCancel"
        :tooltip="$t('labels.cancel_order')"
        @execute='cancelOrderModal(item)'
        icon='cancel' )
    edit-button(
        v-if='item.deliveryJob'
        :tooltip="$t('labels.view_delivery')"
        :to='getDeliveryListLink(item, 2)'
        icon='two_wheeler')
    edit-button(
        v-if="canSend"
        :tooltip="$t('labels.delivery_send_out')"
        @execute='deliverySendOutModal(item)'
        icon='send' )
    edit-button(
        v-if='item.canYouCreateDeliveryJob && !isCanteen'
        :tooltip="$t('labels.create_delivery_job')"
        :to='{name: routeType.DELIVERY_CREATE,params: { id: item.id },}'
        icon='swap_horiz' )
    edit-button(
        :tooltip="$t('labels.order_mail_resend')"
        @execute='resendMail(item)'
        icon='outgoing_mail' )
    edit-button(
        :tooltip="$t('labels.order_mail_preview')"
        :to='{name: routeType.ORDER_PREVIEW_EMAIL,params: { id: item.id },}'
        icon='email'
        target='_blank')
    confirm-modal(
        :value="confirmDeliverSendOutModal"
        :text="$t('orders.do_you_want_send_out_this_order')"
        :processing="processing"
        @onApprove="setDeliverySendOut()"
        @onCancel="cancelDeliverySendOut")
    confirm-modal(
        :value="confirmCancelOrderModal"
        :title="$t('labels.cancel_order')"
        :text="$t('orders.do_you_really_want_to_cancel_this_order', { orderIdentifier: this.cancelOrderItem ? this.cancelOrderItem.identifier : '' })"
        :processing="processing"
        approve-color="red"
        :approve-text="$t('labels.yes')"
        :cancel-text="$t('labels.no')"
        @onApprove="cancelOrder()"
        @onCancel="cancelOrderCancel")
</template>

<style scoped lang="scss"></style>
