import cloneDeep from 'lodash/cloneDeep'

class CompanyAddressFormMapper {
    toEntity(values) {
        return cloneDeep(values)
    }

    fromEntity(entity) {
        return cloneDeep(entity)
    }
}

export default new CompanyAddressFormMapper()
