<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import authType from '@/store/type/authType'
import routeType from '@/router/routeType'
import paramType from '@/router/paramType'
import restaurantType from '@/store/type/restaurantType'
export default {
    name: 'NotificationAlert',
    data() {
        return {
            loaded: false,
        }
    },
    computed: {
        ...mapGetters({
            restaurant: restaurantType.getters.ACTIVE_RESTAURANT,
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
            notificationRegistered: authType.getters.GET_NOTIFICATION_STATE,
            notificationPermissionGranted:
                configType.getters.NOTIFICATION_PERMISSION_GRANTED,
        }),
        isSettingsPage() {
            return (
                this.$route.name === routeType.RESTAURANT_EDIT &&
                this.$route.params.page === paramType.RESTAURANT_BUSINESS_HOURS
            )
        },
        toSettings() {
            return {
                name: routeType.RESTAURANT_EDIT,
                params: {
                    id: this?.restaurant?.id,
                    page: paramType.RESTAURANT_BUSINESS_HOURS,
                },
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.loaded = true
        }, 1000)
    },
}
</script>

<template lang="pug">
v-expand-transition
    v-alert(v-if="(!notificationPermissionGranted || !notificationRegistered) && loaded" type="warning" :icon="false" dense)
        .d-flex.align-center.justify-space-between
            .d-flex.flex-column
                h3.font-weight-regular.mb-0 {{ $t('texts.get_alerts') }}
                small.mb-0 {{ $t('texts.get_alerts_desc') }}
            v-fade-transition
                v-btn(v-if="!isSettingsPage" :to="toSettings" outlined)
                    | {{ $t('labels.goto_settings') }}
</template>

<style scoped lang="scss"></style>
