<template>
    <div style="display: inline-block">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn text icon :color="color" v-on="on" @click="execute">
                    <v-icon>{{ icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ tooltip }}</span>
        </v-tooltip>

        <confirm-modal
            v-model="dialog"
            :title="confirmTitle ? confirmTitle : $t('texts.are_you_sure')"
            :text="confirmText"
            :approve-text="approveText"
            :approve-color="approveColor"
            :cancel-text="cancelText"
            @onApprove="confirm"
        ></confirm-modal>
    </div>
</template>

<script>
import ConfirmModal from '@/components/confirm/ConfirmModal'

export default {
    components: {
        ConfirmModal,
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: true,
        },
        confirmDialog: {
            type: Boolean,
            required: false,
        },
        confirmTitle: {
            type: String,
            default: null,
        },
        confirmText: {
            type: String,
            required: false,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        execute() {
            if (this.confirmDialog) {
                this.dialog = true
            } else {
                this.$emit('execute')
            }
        },
        confirm() {
            this.dialog = false
            this.$emit('execute')
        },
    },
}
</script>
