<template>
    <modal-dialog
        v-model="dialog"
        :title="title ? title : $t('texts.are_you_sure')"
        cancel-button
        :cancel-text="cancelText"
        :ok-text="approveText"
        :ok-color="approveColor"
        :show-overlay="processing"
        @cancel="onCancel"
        @ok="onApprove"
    >
        {{ text }}
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'

export default {
    components: {
        ModalDialog,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        processing: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: false,
        }
    },
    watch: {
        value() {
            this.dialog = this.value
        },
    },
    methods: {
        onApprove() {
            this.$emit('input', false)
            this.$emit('onApprove')
        },
        onCancel() {
            this.$emit('input', false)
            this.$emit('onCancel')
        },
    },
}
</script>
