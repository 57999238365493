import ResponseHandler from '@/api/response/ResponseHandler'
import AbstractRestApi from '@/api/RestApi/AbstractRestApi'
import apiClient from '@/api/ApiClient'

class OrdersApiClient extends AbstractRestApi {
    getBaseUrl() {
        return '/public/orders'
    }

    approve(id, entity) {
        return ResponseHandler.handle(
            apiClient.put(this.getItemIri(id, 'approve'), entity)
        )
    }

    getToken(ccId, restaurantId) {
        return ResponseHandler.handle(
            apiClient.get(
                '/api/v1/cloudcanteen/label/gettoken/' +
                    ccId +
                    '/' +
                    restaurantId
            )
        )
    }

    downloadLabel(ccId, restaurantId, token) {
        return ResponseHandler.handle(
            apiClient.get(
                '/frontend-api/public/cloudcanteen/label/view/' +
                    ccId +
                    '/' +
                    restaurantId +
                    '?token=' +
                    token,
                {
                    transformRequest: [
                        (data, headers) => {
                            delete headers.Authorization
                            return data
                        },
                    ],
                    responseType: 'blob',
                }
            )
        )
    }
}

export default new OrdersApiClient()
