import cloneDeep from 'lodash/cloneDeep'

export default {
    toEntity(entity) {
        for (let relation of entity.relatedRestaurants) {
            if (typeof relation['@id'] === 'number') {
                delete relation['@id']
            }

            if (entity.originalRelations) {
                for (let origRelation of entity.originalRelations) {
                    if (
                        origRelation.restaurant === relation.restaurant &&
                        origRelation['@id'] !== relation['@id']
                    ) {
                        relation['@id'] = origRelation['@id']
                    }
                }
            }
        }

        delete entity.originalRelations
        delete entity.originalCutoffTime
    },

    fromEntity(entity, values) {
        values.originalRelations = cloneDeep(entity.relatedRestaurants)
        values.originalCutoffTime = values.cutoffTime
    },
}
