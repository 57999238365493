import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)
//--v-{color}-base
const colors = {
    white: '#ffffff',
    black: '#000000',
    primary: '#00b33c',
    secondary: '#e8e8e8',
    accent: '#7F4471',
    error: '#f54118',
    info: '#007acc',
    success: '#00b33c',
    warning: '#f3653f',
    brand: '#ed1860',
    brandalt: '#403f3d',
    yellow: '#f2b10c',
    'yellow-1': '#ffebcb',
    'blue-1': '#e1f0fa',
    'g-a1': '#1A1A1A',
    'g-26': '#262626', // text
    'g-73': '#737373', // text-secondary
    'g-99': '#999999', // text-icon
    'g-aa': '#aaaaaa',
    'g-b2': '#B2B2B2', // text-dis
    'g-cc': '#CCCCCC', // text-icon-dis
    'g-e8': '#E8E8E8', // border, button-sec
    'g-ef': '#EFEFEF', // bg, background
    'g-f0': '#F0F0F0', // fill
    'g-f3': '#F3F3F3', // bg, background
    'g-f5': '#F5F5F5',
    'g-f7': '#F7F7F7', // bg, background
}

export default new Vuetify({
    theme: {
        themes: {
            light: colors,
        },
        options: {
            customProperties: true,
            variations: false,
        },
    },
    breakpoint: {
        thresholds: {
            sm: 992,
        },
    },
    icons: {
        iconfont: 'md',
    },
})
