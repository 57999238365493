<script>
export default {
    name: 'DeliveryDetails',
    props: {
        order: Object,
    },
    data() {
        return {
            timer: null,
            pickupIn: null,
        }
    },
    computed: {
        deliveryByYou() {
            return !(
                this.order?.deliveryJob &&
                this.order?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },
        deadline() {
            return this.deliveryByYou
                ? this.order?.startDeliveryAt
                : this.order?.deliveryJob?.etaToPickup ||
                      this.order?.deliveryJob?.pickupTime ||
                      this.order?.startDeliveryAt
        },
        isAsap() {
            return this.order.isAsap
        },
        isCanteen() {
            return !this.order?.userProfile
        },
        isInPreparation() {
            return !this.isScheduled
        },
        isExpired() {
            return this.$date().isAfter(
                this.$date(this.order?.requiredDeliveryTime)
            )
        },
        isScheduled() {
            return (
                !this.order?.preparationConfirmed &&
                !this.isAsap &&
                !this.isCanteen
            )
        },
    },
    mounted() {
        if (!this.deliveryByYou) {
            this.calcTime()
            this.startClock()
        }
    },
    beforeDestroy() {
        if (!this.deliveryByYou) this.stopClock()
    },
    methods: {
        startClock() {
            this.timer = setInterval(this.calcTime, 60000)
        },
        stopClock() {
            clearInterval(this.timer)
        },
        calcTime() {
            if (this.deadline)
                this.pickupIn = this.$date(this.deadline).diff(
                    this.$date(),
                    'minute'
                )
        },
    },
}
</script>

<template lang="pug">
.d-flex.flex-column.align-center
    template(v-if="isExpired")
        span {{ $t('orders.delivery_by') }}
        span [{{deliveryByYou && !isCanteen ? order.requiredDeliveryTime : order.startDeliveryAt | toDate('YYYY.MM.DD. HH:mm') }}]
    template(v-else)
        //delivery by the restaurant and not CC
        template(v-if="deliveryByYou && !isCanteen")
            // order is in preparation
            template(v-if="isInPreparation")
                // the order is an ASAP order
                template(v-if="isAsap")
                    span {{ $t('orders.deliver') }}&nbsp;
                        span.text-h6.font-weight-bold {{ $t('orders.asap') }}
                    span.text-h6.font-weight-bold(:inner-html.prop="order.requiredDeliveryTime | toSchedule")
                // the order is NOT an ASAP order, but it is in preparation
                template(v-else)
                    span {{ $t('orders.delivery_by') }}&nbsp;
                        span.text-h6.font-weight-bold(:inner-html.prop="order.requiredDeliveryTime | toSchedule")
            // order is scheduled
            template(v-else)
                span {{ $t('orders.delivery_by') }}&nbsp;
                    span.text-h6.font-weight-bold(:inner-html.prop="order.requiredDeliveryTime | toSchedule")

        // delivery by stuart or CC
        template(v-else)
            // order is in preparation
            template(v-if="isInPreparation")
                // the order still in the restaurant
                template(v-if="pickupIn > -1")
                    span(v-if="isCanteen" v-html="$t('orders.pickup_in', {min: pickupIn})")
                    span(v-html="$t('orders.pickup_by_stuart_in', {min: pickupIn})" v-else)
                    span [{{order.startDeliveryAt | toDate('HH:mm') }}]
                // the order is already picked up by the stuart
                template(v-else)
                    span {{ $t('orders.order_under_delivery') }}
                    span [{{order.startDeliveryAt | toDate('YYYY.MM.DD. HH:mm') }}]
            // order is scheduled
            template(v-else)
                span
                    template(v-if="isCanteen") {{ $t('orders.pickup_at') }}&nbsp;
                    template(v-else) {{ $t('orders.pickup_by_stuart') }}&nbsp;
                    span.text-h6.font-weight-bold(:inner-html.prop="order.startDeliveryAt | toSchedule")
                | {{order.startDeliveryAt | toDate }}
    v-chip.font-weight-bold.ml-2(v-if="deliveryByYou" color="blue" label small dark) {{ $t('orders.delivery_by_you') }}
</template>
