import ObjectMapper from 'object-mapper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

export default {
    data() {
        return {
            mapOrder: {
                created_at: 'createdAt',
                status: 'status',
                identifier: 'identifier',
                delivery_job_id: 'deliveryJob.id',
                is_delivery_processed: 'isDeliveryProcessed',
                sent_ratio: 'sentRatio',
                updated_at: 'updatedAt',
                preparation_confirmed: 'preparationConfirmed',
                restaurant_id: 'restaurant',
                restaurant_name: 'restaurantName',
                user_profile_id: 'userProfile.@id',
                company_name: 'companyName',
                company_id: 'company',
                required_delivery_time: 'requiredDeliveryTime',
                price_sum: 'priceTotal',
                currency: 'ordering.currency',
                currency_locale: 'ordering.currencyLocale',
                cloudcanteen_id: 'cloudcanteen',
                warning: 'warning',
                warnings: 'warnings',
                is_active_for_restaurant: 'isActiveForRestaurant',
            },
            mapDeliveryJob: {
                error: 'error',
                courier_status: 'courierStatus',
                driver_name: 'driverName',
                driver_phone: 'driverPhone',
                status: 'status',
                updated_at: 'updatedAt',
                eta_to_destination: 'etaToDestination',
                eta_to_pickup: 'etaToPickup',
                delivery_method: 'deliveryMethod',
                courier_lat: 'courierLat',
                courier_long: 'courierLong',
                created_at: 'createAt',
                tracking_url: 'trackingUrl',
                latest_courier_error_delivery_method:
                    'latestCourierErrorDeliveryMethod',
                latest_courier_error: 'latestCourierError ',
                active_delivery_method: 'activeDeliveryMethod',
                pickup_time: 'pickupTime',
                disable_auto_create: 'disableAutoCreate',
                warning: 'warning',
                warnings: 'warnings',
            },
            mapDeliveryProviderJob: {
                error: 'error',
                courier_status: 'courierStatus',
                driver_name: 'driverName',
                driver_phone: 'driverPhone',
                status: 'status',
                delivery_uid: 'deliveryUid',
                delivery_job_id: 'deliveryJob.id',
                updated_at: 'updatedAt',
                eta_to_destination: 'etaToDestination',
                eta_to_pickup: 'etaToPickup',
                delivery_method: 'deliveryMethod',
                create_at: 'createAt',
                tracking_url: 'trackingUrl',
            },
        }
    },
    methods: {
        objectMapper(item, object, map) {
            item = cloneDeep(item)
            object = cloneDeep(object)
            map = cloneDeep(map)
            Object.entries(object).forEach(([key, value]) => {
                object[key] = value.new
            })
            return merge(item, ObjectMapper(object, map))
        },
    },
}
