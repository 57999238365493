<script>
import { Printd } from 'printd'
import ModalPrintLabelDialog from '@/components/dialog/ModalPrintLabelDialog'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import printJS from 'print-js'
import IdFromIri from '@/services/IdFromIri'
import canteenOrderIdentifier from '@/mixins/canteenOrderIdentifier'

export default {
    name: 'PrintLabel',
    components: {
        ModalPrintLabelDialog,
    },
    mixins: [canteenOrderIdentifier],
    props: {
        order: null,
        classes: null,
    },
    data() {
        return {
            printd: null,
            dialog: false,
            orderId: null,
            pickupInfo: null,
        }
    },
    computed: {
        ccId() {
            return (
                this.order?.cloudcanteen?.identifier ||
                this.order?.residentialcanteen?.identifier
            )
        },
        restaurantId() {
            return IdFromIri.getId(this.order?.restaurant)
        },
        orderIdentifier() {
            return this.getCanteenOrderIdentifier(this.order?.identifier)
        },
        pickupTime() {
            return this.order.requiredDeliveryTime
        },
    },
    mounted() {
        this.printd = new Printd()
    },
    methods: {
        print() {
            this.printd.print(this.$refs.print, [this.css])
        },
        showModal() {
            this.dialog = true
        },
        hideModal() {
            this.dialog = false
        },
        downloadLabel() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadLabel(this.restaurantId, this.ccId)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    link.download = this.orderIdentifier
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(console.error)
        },
        printLabel() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadLabel(this.restaurantId, this.ccId)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
    },
}
</script>

<template>
    <div>
        <div v-if="order" class="print-label">
            <v-btn
                :class="classes ? classes : ''"
                color="info"
                small
                @click="showModal"
            >
                {{ $t('orders.print_labels') }}
                <v-icon class="ml-1" small>print</v-icon>
            </v-btn>
        </div>

        <modal-print-label-dialog
            :value="dialog"
            text="TEXTTT"
            :order-id="orderId"
            :pickup-info="pickupInfo"
            :title="$t('texts.print_item_labels')"
            :approve-text="$t('actions.back')"
            :cancel-text="$t('actions.leave')"
            @close="hideModal"
            @downloadLabels="downloadLabel"
            @printLabels="printLabel"
        >
            <div>Order #{{ orderIdentifier }}</div>
            <div>
                Pickup: {{ this.$date(pickupTime).format('D MMM YYYY, HH:mm') }}
            </div>
        </modal-print-label-dialog>
    </div>
</template>

<style scoped lang="scss"></style>
