<script>
export default {
    name: 'Identifier',
    props: {
        item: Object,
        getId: Function,
        showEditLink: Boolean,
        routeType: Object,
    },
}
</script>

<template lang="pug">
.d-flex.flex-column
    router-link.text-nowrap(v-if='showEditLink' :to="getId(item,'@id',routeType.ORDER_EDIT,'id')")
        | {{ item.identifier }}
    span.text-nowrap(v-else='') {{ item.identifier }}
    span.text-nowrap.g-73--text(v-if='(item.cloudcanteen || item.residentialcanteen) && item.userProfile')
        template(v-if='item.cloudcanteen')
            | {{ item.cloudcanteen.identifier }}
        template(v-if='item.residentialcanteen')
            | {{ item.residentialcanteen.identifier }}
    small.text-nowrap.g-73--text
        | {{ item.createdAt | transformDate }}
</template>

<style scoped lang="scss"></style>
