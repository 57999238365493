<script>
import { mapGetters } from 'vuex'
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import configType from '@/store/type/configType'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import Cleave from 'cleave.js'
import {
    DATE_FORMAT,
    TIME_FORMAT,
    DATE_TIME_FORMAT,
} from '@/const/DateTimeFormats'

export default {
    components: {
        VueCtkDateTimePicker,
    },
    mixins: [ErrorMsgInputMixin],
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
        required: Boolean,
        filter: Boolean,
        onlyDate: Boolean,
        onlyTime: Boolean,
        blockDate: String,
        value: String,
        min: String,
        max: String,
    },
    data() {
        return {
            valid: false,
            active: false,
            displayValue: null,
            validationMessage: undefined,
            cleave: null,
        }
    },
    computed: {
        ...mapGetters({
            locale: configType.getters.CURRENT_LOCALE,
        }),
        appendIcon() {
            return this.value && !this.required ? 'clear' : undefined
        },
        prependSlot() {
            return this.filter ? 'prepend-inner' : 'prepend'
        },
        backgroundColor() {
            return this.filter ? 'white' : undefined
        },
        format() {
            if (this.onlyDate) return DATE_FORMAT
            if (this.onlyTime) return TIME_FORMAT
            return DATE_TIME_FORMAT
        },
        blocks() {
            if (this.onlyDate) return [4, 2, 2]
            if (this.onlyTime) return [2, 2]
            return this.blockDate ? [10, 2, 2] : [4, 2, 2, 2, 2]
        },
        delimiters() {
            if (this.onlyDate) return ['-', '-']
            if (this.onlyTime) return [':']
            return this.blockDate ? [' ', ':'] : ['-', '-', ' ', ':']
        },
        prefix() {
            return this.blockDate
                ? this.$date(this.blockDate).format(DATE_FORMAT)
                : null
        },
        minDate() {
            return this.blockDate
                ? this.$date(this.blockDate).format(DATE_FORMAT)
                : this.min
                ? this.onlyTime
                    ? this.min
                    : this.$date(this.min).format(DATE_FORMAT)
                : undefined
        },
        maxDate() {
            return this.blockDate
                ? this.$date(this.blockDate).format(DATE_FORMAT)
                : this.max
                ? this.onlyTime
                    ? this.max
                    : this.$date(this.max).format(DATE_FORMAT)
                : undefined
        },
        disabledHours() {
            if (!this.onlyTime || !this.min) return undefined
            let minHour = this.min.split(':')?.[0]
            let minMinute = this.min.split(':')?.[1]
            return [...Array(24).keys()]
                .filter(
                    (hour) =>
                        hour < minHour || (59 == minMinute && hour == minHour)
                )
                ?.map((hour) => {
                    let string = JSON.stringify(hour)
                    return string.length === 1 ? `0${string}` : string
                })
        },
        cleaveProperties() {
            return {
                prefix: this.prefix,
                prefixLength: this.prefix?.length || 0,
                blocks: this.blocks,
                blocksLength: this.blocks?.length,
                maxLength: this.blocks?.reduce((p, c) => p + c, 0),
                delimiters: this.delimiters,
                dateMin: this.minDate,
                dateMax: this.maxDate,
                onValueChanged: ({ target }) => {
                    this.displayValue = target.value
                    // e.target = { value: '5100-1234', rawValue: '51001234' }
                },
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                this.validate(value)
                this.setDisplayValue(value)
            },
        },
        cleaveProperties: {
            deep: true,
            handler(properties) {
                this.cleave.properties = {
                    ...this.cleave.properties,
                    ...properties,
                }
            },
        },
    },
    mounted() {
        let input = document.querySelector(
            `#input-${this.$refs.datePicker._uid}`
        )

        if (input) {
            this.cleave = new Cleave(input, this.cleaveProperties)
        }
    },
    beforeDestroy() {
        if (this.cleave) {
            this.cleave.destroy()
            this.cleave = null
        }
    },
    methods: {
        validate(value) {
            this.validationMessage = undefined
            if (value) {
                if (this.onlyTime || this.onlyDate) {
                    if (this.onlyTime)
                        this.valid = Boolean(
                            value.match(/[0-2][0-9]:[0-5][0-9]/)
                        )
                    else
                        this.valid = this.$date(
                            value,
                            this.format,
                            true
                        ).isValid()
                    if (this.valid) {
                        if (this.minDate) this.validateTime(value, 'min')
                        if (this.maxDate) this.validateTime(value, 'max')
                    } else {
                        this.validationMessage = this.$t(
                            'validations.messages.period-hour-format'
                        )
                    }
                } else {
                    this.valid = this.$date(value, DATE_TIME_FORMAT).isValid()
                    if (!this.valid)
                        this.validationMessage = this.$t(
                            'validations.messages.period-hour-format'
                        )
                }
            } else {
                this.valid = !this.required
                if (!this.valid && this.required)
                    this.validationMessage = this.$t(
                        'validations.messages.required'
                    )
            }
        },
        validateTime(value, type) {
            const a = this.onlyTime ? `0000-00-0 ${value}` : value
            const b = this.onlyTime
                ? `0000-00-0 ${this[`${type}Date`]}`
                : this[`${type}Date`]
            this.valid = this.$date(type === 'min' ? a : b).isSameOrAfter(
                type === 'min' ? b : a
            )
            if (!this.valid)
                this.validationMessage = this.$t(
                    `validations.messages.${type}-value`,
                    { hour: this[`${type}Date`] }
                )
        },
        setDate(value) {
            this.$emit(
                'input',
                value
                    ? !(this.onlyDate || this.onlyTime)
                        ? this.$date(value).format()
                        : value
                    : value
            )
        },
        setDisplayValue(value) {
            value = this.$generalizeDate(value)
            this.$set(
                this,
                'displayValue',
                value
                    ? !(this.onlyDate || this.onlyTime)
                        ? this.$date(value, this.format).format(this.format)
                        : value
                    : undefined
            )
        },
        onManualSet() {
            this.$nextTick(() => {
                this.validate(this.displayValue)
                if (this.valid) {
                    this.setDate(this.displayValue)
                }
            })
        },
        isShown() {
            this.active = true
        },
        isHidden() {
            this.active = false
        },
    },
}
</script>

<template lang="pug">
.date-picker
    v-text-field(
        :value="displayValue"
        :label='labelText'
        :append-icon="appendIcon"
        :error="!valid"
        :error-messages='validationMessage || errorMessages'
        :background-color="backgroundColor"
        :outlined='filter'
        :dense='filter'
        :readonly="!editable"
        ref="datePicker"
        @change="onManualSet"
        @click:append="setDate(undefined)")
        vue-ctk-date-time-picker(
            :slot="prependSlot"
            :value="value"
            :format="format"
            :min-date="minDate"
            :max-date="maxDate"
            :only-time="onlyTime"
            :only-date="onlyDate"
            :disabled="!editable"
            :no-value-to-custom-elem="true"
            :disabled-hours="disabledHours"
            :first-day-of-week="locale === 'hu' ? 1 : 0"
            color="#00b33c"
            @input="setDate"
            @is-shown="isShown"
            @is-hidden="isHidden"
            no-button
            no-button-now)
            v-btn(:color="active ? 'primary' : 'g-73'" icon small)
                v-icon.c-pointer
                    template(v-if="onlyTime") access_time
                    template(v-else) event


</template>

<style scoped>
.date-picker >>> .datepicker {
    margin: 25px 0;
}
</style>
