<template>
    <div>
        <v-data-table
            v-model="selected"
            :items="items"
            :headers="tableHeaders"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :show-select="rowSelect"
            class="data-table border-g-e8"
            must-sort
            disable-filtering
            disable-pagination
            hide-default-footer
            item-key="id"
            @update:sort-by="sortBy = $event"
            @update:sort-desc="sortDesc = $event"
        >
            <template
                v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData"
            >
                <slot :name="name" v-bind="slotData" />
            </template>
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

            <template v-slot:[`item._rowActions`]="{ item }">
                <div :class="['row-action', { 'opacity-1': visibleActions }]">
                    <slot name="rowActions" :item="item"></slot>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        filters: {
            type: Object,
            default: () => {},
        },
        rowSelect: {
            type: Boolean,
        },
        rowAction: {
            type: Boolean,
        },
        visibleActions: {
            type: Boolean,
        },
        actionWidth: {
            type: [String, Number],
            default: undefined,
        },
    },
    data() {
        return {
            selected: [],
        }
    },
    computed: {
        tableHeaders() {
            let tableHeaders = cloneDeep(this.headers)

            if (this.rowAction) {
                tableHeaders.push({
                    value: '_rowActions',
                    sortable: false,
                    text: '',
                    class: 'row-actions-header',
                    ...(this.actionWidth && { width: this.actionWidth }),
                })
            }

            return tableHeaders
        },
        sortBy: {
            get() {
                return Object.keys(this.filters?.order || {})?.[0]
            },
            set(val) {
                let filters = cloneDeep(this.filters)
                filters.order = {}
                filters.order[val] = this.sortDesc ? 'desc' : 'asc'
                this.$emit('update:filters', filters)
            },
        },
        sortDesc: {
            get() {
                return this.filters?.order?.[this.sortBy] === 'desc'
            },
            set(val) {
                this.$emit('update:filters', {
                    ...this.filters,
                    ...{
                        order: { [this.sortBy]: val ? 'desc' : 'asc' },
                    },
                })
            },
        },
    },
    watch: {
        selected(selected) {
            const ids = []

            selected.forEach((value) => {
                ids.push(value.id)
            })

            this.$emit('onRowSelected', ids)
        },
    },
}
</script>

<style lang="scss">
.data-table {
    tr {
        min-height: 60px;
        height: auto;
    }

    .row-actions-header {
        min-width: 120px;
    }

    .row-action {
        display: flex;
        justify-content: flex-end;
    }

    .small {
        font-size: 0.8em;
    }

    th.sortable {
        white-space: nowrap;
    }

    @media (hover: hover) {
        .row-action {
            opacity: 0;
        }

        tr:hover .row-action {
            opacity: 1;
        }
    }
}
</style>
