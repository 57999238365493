<script>
import EditButton from '@/components/table/buttons/EditButton'
import ConfirmModal from '@/components/confirm/ConfirmModal'
export default {
    name: 'Warning',
    components: {
        ConfirmModal,
        EditButton,
    },
    props: {
        item: null,
    },
    computed: {
        warnings() {
            let warnings = this.item.warning
                ? this.item.warnings
                : this.item?.deliveryJob?.warnings
            if (typeof warnings === 'string') warnings = JSON.parse(warnings)
            return warnings || []
        },
        showWarnings() {
            let str = ''
            this.warnings?.forEach((val, index) => {
                str += `${this.$t(`delivery_warning.${val}`)}`
                if (this.warnings.length !== index + 1) str += `,\n`
            })
            return str
        },
    },
}
</script>

<template lang="pug">
    div
        v-chip(v-for="(warning, i) in warnings" :key="i" small color="red" class="rounded px-2 py-2 mb-1 white--text w-full")
            | {{ $t(`delivery_warning.${warning}`) }}
            .d-inline-flex.ml-1
                edit-button(
                    :tooltip="$t('orders.delete_warning')"
                    icon='close'
                    iconsize='small'
                    xsmall=true
                    color="#fff"
                    @execute="$emit('execute')"
                )
</template>

<style lang="scss">
.editable-field {
    width: 100px;
}
</style>
