import dataMapper from '@/components/mixins/dataMapper'
import Config from '@/config.loader'
import Pusher from 'pusher-js'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import { EventBus } from '@/plugins/events'

export default {
    mixins: [dataMapper],
    data() {
        return {
            pusher: null,
            logToConsole: false,
        }
    },
    computed: {
        ...mapGetters({
            jwt: authType.getters.GET_JWT,
        }),
        filtersOn() {
            return (
                Object.keys(this.filters || {}).findIndex(
                    (filter) =>
                        !['order', 'page', 'itemsPerPage'].includes(filter)
                ) > 0
            )
        },
    },
    created() {
        const wsHost = Config.getConfigValue('VUE_APP_PUSHER_WS_HOST')
        const wsPort = Config.getConfigValue('VUE_APP_PUSHER_WS_PORT') || 443
        const wsEventsProtocol =
            Config.getConfigValue('VUE_APP_PUSHER_WS_EVENTS_PROTOCOL') || '//'
        const authEndpoint =
            Config.getConfigValue('VUE_APP_PUSHER_AUTH_API') ||
            '/api/v1/broadcasting/auth'
        this.pusher = new Pusher(
            Config.getConfigValue('VUE_APP_PUSHER_APP_KEY') || 'Events',
            {
                wsHost: wsHost,
                wsPort: wsPort,
                wssHost: wsHost,
                wssPort: wsPort,
                forceTLS: false,
                enabledTransports: ['ws', 'wss'],
                authEndpoint: `${wsEventsProtocol}${wsHost}:${wsPort}${authEndpoint}`,
                auth: {
                    headers: {
                        Authorization: `Bearer ${this.jwt}`,
                    },
                },
            }
        )
        this.pusher.logToConsole = this.logToConsole
        Pusher.log = (msg) => {
            if (this.logToConsole) console.log(`%c${msg}`, 'color: #55da95')
        }
        Pusher.err = (msg) => {
            console.log(`%c[PUSHER ERROR]${msg}`, 'color: #f00')
        }

        this.pusher.connection.bind('error', function (err) {
            if (err?.error?.data?.code === 4004) Pusher.err('Over limit!')
            else Pusher.err(err)
        })
    },
    methods: {
        findOrder(id, key) {
            return this.$refs.iterator.items.findIndex(
                (item) => (key && item[key] ? item[key].id : item.id) === id
            )
        },
        findOrders(id, key) {
            let result = []
            this.$refs.iterator.items.forEach((item, index) => {
                if ((key && item[key] ? item[key].id : item.id) === id)
                    result.push(index)
            })
            return result
        },
        updateItem(data, itemKey, map, key) {
            if (data && itemKey >= 0 && map) {
                this.$set(
                    key
                        ? this.$refs.iterator.items[itemKey]
                        : this.$refs.iterator.items,
                    key ? key : itemKey,
                    this.objectMapper(
                        key
                            ? this.$refs.iterator.items[itemKey][key]
                            : this.$refs.iterator.items[itemKey],
                        data,
                        map
                    )
                )
            }
        },
        updateList() {
            EventBus.$emit('updateList', this.api)
        },
    },
}
