import configType from './type/canteenType'
export default {
    state: {
        cc: null,
        ccList: [],
    },
    getters: {
        [configType.getters.CANTEEN](state) {
            return state.cc
        },
        [configType.getters.CANTEEN_LIST](state) {
            return state.ccList
        },
    },
    mutations: {
        [configType.mutations.SET_CANTEEN](state, payload) {
            state.cc = payload
        },
        [configType.mutations.SET_CANTEEN_LIST](state, payload) {
            state.ccList = payload
        },
    },
}
