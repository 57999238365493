import configType from './type/restaurantType'
import RestApiCollection from '@/api/RestApiCollection'
import ApiClientType from '@/api/RestApiType'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import cloneDeep from 'lodash/cloneDeep'
import IdFromIri from '@/services/IdFromIri'
export default {
    state: {
        deliverySettings: null,
        restaurant: null,
        activeRestaurant: null,
        restaurantId: null,
    },
    getters: {
        [configType.getters.DELIVERY_SETTINGS](state) {
            return state.deliverySettings
        },
        [configType.getters.RESTAURANT](state) {
            return state.restaurant
        },
        [configType.getters.ACTIVE_RESTAURANT](state) {
            return state.activeRestaurant
        },
        [configType.getters.RESTAURANT_ID](state) {
            return state.restaurantId
        },
    },
    mutations: {
        [configType.mutations.SET_DELIVERY_SETTINGS](state, payload) {
            state.deliverySettings = payload
        },
        [configType.mutations.SET_RESTAURANT](state, payload) {
            state.restaurant = payload
        },
        [configType.mutations.SET_ACTIVE_RESTAURANT](state, payload) {
            state.activeRestaurant = payload
        },
        [configType.mutations.SET_ACTIVE_RESTAURANT_ID](state, payload) {
            state.restaurantId = payload
        },
    },
    actions: {
        [configType.actions.SET_ACTIVE_RESTAURANT_ID]({ commit }, payload) {
            return new Promise((resolve) => {
                let id = IdFromIri.getId(payload)
                commit(configType.mutations.SET_ACTIVE_RESTAURANT_ID, id)
                resolve(id)
            })
        },
        [configType.actions.GET_RESTAURANT]({ commit }, id) {
            return new Promise((resolve, reject) => {
                try {
                    RestApiCollection.get(ApiClientType.RESTAURANTS)
                        .get(id)
                        .then((data) => {
                            const entity = MapperCollection.get(
                                MapperType.RESTAURANT_FORM
                            ).fromEntity(data)

                            commit(
                                configType.mutations.SET_RESTAURANT,
                                cloneDeep(entity)
                            )
                        })
                        .catch((reason) => {
                            return reject(reason)
                        })
                } catch (e) {
                    return reject(e)
                }
            })
        },
    },
}
