<template lang="pug">
    .entity-selector(:ref="`entitySelect-${this._uid}`"
        :class="{'has-error': errorMessages.length, 'entity-selector-small': small, 'entity-selector-overflow' : !innerHeight, 'entity-select-dialog': appendOuterIcon}")
        v-select.test-0(
            v-if="!autocomplete && type === 'select'"
            v-model="selectedValue"
            :label="labelText"
            :disabled="selectIsDisabled || disabled"
            :items="selectItems"
            item-text="label"
            :item-value="itemValue"
            :append-icon="appendIcon"
            :append-outer-icon="appendOuterIcon"
            :name="name"
            :height="innerHeight"
            :error-messages="errorMessages"
            :multiple="multiSelect"
            :chips="multiSelect"
            :deletable-chips="multiSelect"
            :hide-details="hideDetails"
            :outlined="filter"
            :dense="filter"
            :background-color="filter ? 'white' : undefined"
            @change="selectionChanged"
            @click:append-outer="openDialog"
            @click:append="clear")
        div.test-1(
            v-else-if="!autocomplete && type === 'checkbox'"
            :class="{ 'error--text': hasError }")
            label(v-if="label") {{ $t(label) }}
            v-row
                v-col(:cols="12" :sm="colSm" class="py-0")
                    v-checkbox(
                        v-for="(item, index) in selectItems"
                        :key="item.id"
                        v-if="index <= colBreak"
                        v-model="selectedValue"
                        class="mr-4 mt-3"
                        :value="item.key"
                        :input-value="selectedValue"
                        :name="name"
                        :label="item.label"
                        type="checkbox"
                        color="success"
                        :disabled="isInvalid(item)"
                        hide-details="auto"
                        :error="hasError")
                v-col(:cols="12" :sm="colSm" class="py-0")
                    v-checkbox(
                        v-for="(item, index) in selectItems"
                        :key="item.id"
                        v-if="index > colBreak"
                        v-model="selectedValue"
                        class="mr-4 mt-3"
                        :value="item.key"
                        :input-value="selectedValue"
                        :name="name"
                        :label="item.label"
                        type="checkbox"
                        color="success"
                        :disabled="isInvalid(item)"
                        hide-details="auto"
                        :error="hasError")

        v-autocomplete.test-2(
            v-else-if="reverse"
            v-model="computedValue"
            :label="$t(label)"
            :disabled="selectIsDisabled || disabled"
            :items="selectItems"
            item-text="label"
            :item-value="itemValue"
            :append-icon="appendIcon"
            :append-outer-icon="appendOuterIcon"
            :name="name"
            :height="innerHeight"
            :error-messages="errorMessages"
            :multiple="multiSelect"
            :small-chips="multiSelect"
            :deletable-chips="multiSelect"
            :hide-details="hideDetails"
            :outlined="filter"
            :dense="filter"
            :background-color="filter ? 'white' : undefined"
            @change="selectionChanged"
            @click:append-outer="openDialog"
            @click:append="clear")

        v-autocomplete.test-3(
            v-else
            v-model="selectedValue"
            :label="$t(label)"
            :disabled="selectIsDisabled || disabled"
            :items="selectItems"
            item-text="label"
            :item-value="itemValue"
            :append-icon="appendIcon"
            :append-outer-icon="appendOuterIcon"
            :name="name"
            :height="innerHeight"
            :error-messages="errorMessages"
            :multiple="multiSelect"
            :small-chips="multiSelect"
            :deletable-chips="multiSelect"
            :hide-details="hideDetails"
            :outlined="filter"
            :dense="filter"
            :background-color="filter ? 'white' : undefined"
            @change="selectionChanged"
            @click:append-outer="openDialog"
            @click:append="clear")
        modal-dialog.entity-select-modal(
            v-model="selectorDialog"
            :title="$t(label)"
            @ok="acceptSelection"
            scrollable
            cancel-button)
                v-checkbox(
                    v-for="value in dialogValues"
                    :key="`dialogList-${value}`"
                    v-model="dialogSelection"
                    class="mr-4 mt-3"
                    :value="value"
                    :input-value="dialogSelection"
                    :name="name"
                    :label="getLabel(value)"
                    type="checkbox"
                    color="success"
                    hide-details="auto")


</template>

<script>
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import selectapiType from '@/store/type/selectapiType'
import ErrorMsgInputMixin from './InputMixins/ErrorMsgInputMixin'
import ModalDialog from '@/components/dialog/ModalDialog'

export default {
    components: { ModalDialog },
    mixins: [ErrorMsgInputMixin],
    props: {
        value: {
            type: [Number, String, Object, Array],
            default: null,
        },
        entity: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'select',
        },
        hideDetails: {
            type: [Boolean, String],
            default: false,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        queryParams: {
            type: Object,
            default: null,
        },
        itemsFilter: {
            type: Function,
            default: null,
        },
        errorMessages: {
            type: Array,
            default: () => [],
        },
        invalid: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Number,
            default: 1,
        },
        height: {
            type: [Number, Boolean],
            default: 32,
        },
        extraOptions: Array,
        disabled: Boolean,
        small: Boolean,
        filter: Boolean,
        hideControl: Boolean,
        requestDisabled: Boolean,
        autocomplete: Boolean,
        multiSelect: Boolean,
        useIdField: Boolean,
        useNameField: Boolean,
        translateItems: Boolean,
        needsUpdate: Boolean,
        reverse: Boolean,
    },
    data() {
        return {
            selectedValue: undefined,
            dialogValues: undefined,
            dialogSelection: undefined,
            items: [],
            selectIsDisabled: false,
            showClearIcon: false,
            lastQueryParams: null,
            itemsFilled: false,
            appendOuterIcon: undefined,
        }
    },
    computed: {
        innerHeight() {
            return this.height && this.height >= 32
                ? this.height +
                      (!this.autocomplete && this.type === 'select' ? 10 : 0)
                : undefined
        },
        colBreak() {
            return Math.floor(this.selectItems.length / 2)
        },
        appendIcon() {
            return this.hideControl
                ? ''
                : this.showClearIcon
                ? 'clear'
                : this.selectedValue
                ? 'expand_more'
                : ''
        },
        selectItems() {
            if (this.translateItems) {
                const items = cloneDeep(this.items)

                items.forEach((item) => {
                    item.label = this.$t(item.label)
                })

                return items
            }

            return this.items
        },

        computedValue: {
            get() {
                if (this.reverse) {
                    return this.selectItems
                        .filter((el) => {
                            return (
                                this.value.findIndex((e) => {
                                    return e == el.key
                                }) === -1
                            )
                        })
                        .map((el) => {
                            return el.key
                        })
                } else {
                    return this.value
                }
            },
            set(val) {
                let value = null
                if (this.reverse) {
                    value = this.selectItems
                        .filter((el) => {
                            return val.indexOf(el.key) === -1
                        })
                        .map((el) => {
                            return el.key
                        })
                } else {
                    value = val
                }
                this.$emit('input', value)
                this.showClearIcon =
                    (this.multiSelect
                        ? value.length > 0
                        : value != undefined) && this.clearable
            },
        },

        colSm() {
            return Math.floor(12 / this.columns)
        },

        hasError() {
            return this.errorMessages.length > 0
        },
        itemValue() {
            return this.useIdField ? 'id' : this.useNameField ? 'name' : 'key'
        },
        selectorDialog: {
            get() {
                return !!this.dialogValues
            },
            set() {
                this.dialogValues = undefined
            },
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (this.multiSelect && value == null) {
                    value = []
                }
                this.selectionChanged()
                this.selectedValue = value ? value : undefined
                this.showClearIcon =
                    (this.multiSelect && value instanceof Array
                        ? value.length > 0
                        : value != undefined) && this.clearable
            },
        },
        selectedValue(value) {
            this.$emit('input', value)
            this.showClearIcon =
                (this.multiSelect ? value.length > 0 : value != undefined) &&
                this.clearable
        },
        entity: {
            immediate: true,
            handler() {
                this.updateList()
            },
        },
        queryParams() {
            if (!isEqual(this.queryParams, this.lastQueryParams)) {
                this.updateList()
            }
        },
        needsUpdate: 'updateList',
    },
    mounted() {
        this.selectionChanged()
    },
    methods: {
        clear() {
            this.selectedValue = this.multiSelect ? [] : undefined
        },
        getLabel(value) {
            return this.selectItems.find(
                (item) => item[this.itemValue] === value
            )?.label
        },
        acceptSelection() {
            this.selectedValue = cloneDeep(this.dialogSelection)
            this.dialogSelection = undefined
            this.dialogValues = undefined
        },
        openDialog() {
            this.dialogSelection = cloneDeep(this.selectedValue)
            this.dialogValues = cloneDeep(this.selectedValue)
        },
        selectionChanged() {
            if (
                this.innerHeight &&
                !(!this.autocomplete && this.type === 'checkbox')
            ) {
                let selector = this.$refs[`entitySelect-${this._uid}`]
                if (selector) {
                    this.$nextTick(() => {
                        let selection = selector.querySelector(
                            '.v-select__selections'
                        )
                        if (selection) {
                            this.appendOuterIcon =
                                selection.scrollHeight > this.innerHeight
                                    ? 'more_horiz'
                                    : undefined
                        }
                    })
                }
            }
        },
        updateList() {
            if (this.requestDisabled) {
                return
            }

            this.selectIsDisabled = true
            this.lastQueryParams = this.queryParams

            this.$store
                .dispatch(
                    selectapiType.getActionName(
                        this.entity,
                        selectapiType.actions.LOAD
                    ),
                    { query: this.queryParams }
                )
                .then((data) => {
                    this.selectIsDisabled = false
                    this.items = this.itemsFilter
                        ? this.itemsFilter(data)
                        : data
                    this.extraOptions && this.extraOptions.length
                        ? (this.items = [...this.extraOptions, ...this.items])
                        : this.items
                    this.$emit('getItems', this.items)
                    this.itemsFilled = true
                    this.validateSelectedValue()
                })
        },
        validateSelectedValue() {
            if (
                !this.itemsFilled ||
                this.selectedValue === undefined ||
                (this.multiSelect && this.selectedValue.length === 0)
            ) {
                return
            }

            if (this.multiSelect) {
                const validList = []

                this.selectedValue.forEach((selected) => {
                    const found = this.items.find(
                        (item) => item[this.itemValue] === selected
                    )

                    if (found !== undefined) {
                        validList.push(selected)
                    }
                })

                this.selectedValue = validList
            } else {
                const found = this.items.find(
                    (item) => item[this.itemValue] === this.selectedValue
                )

                if (found === undefined) {
                    this.clear()
                }
            }
        },
        isInvalid(item) {
            if (this.invalid?.length) {
                const idx = this.invalid.indexOf(item.key)
                return idx !== -1 ? true : false
            }
            return false
        },
    },
}
</script>
<style lang="scss">
.entity-selector {
    &.entity-select-dialog {
        .v-select__selections {
            padding-right: 28px;
        }
    }
    :not(.entity-selector-overflow) {
        .v-input__slot {
            .v-select__slot {
                height: inherit;
                overflow: hidden;
                align-items: flex-end;
            }
        }
        .v-input__append-outer {
            position: absolute;
            right: 28px;
        }
    }
    .v-text-field {
        margin-top: 0;
        padding-top: 0;
    }
    .v-input__slot {
        transition: margin-bottom var(--transition-speed);
    }
    .v-text-field__details,
    .v-messages {
        transition: min-height var(--transition-speed);
    }
    &:not(.has-error) {
        .v-input__slot {
            margin-bottom: 0;
        }
        .v-text-field__details,
        .v-messages {
            min-height: 0;
        }
    }
    &.entity-selector-small {
        .v-select.v-text-field input {
            padding: 0;
            max-height: 20px;
            font-size: 14px;
        }
    }
}
</style>
