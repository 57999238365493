import Vue from 'vue'
import authType from '@/store/type/authType'
import { ROLE_ADMIN } from '@/enum/roles'
import UsersApiClient from '@/api/RestApi/UsersApiClient'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import Config from '@/config.loader'

const subscribeToFirebaseTopic = (topic, commit, dispatch, mutation) =>
    Vue.prototype.$fbdb.ref(topic).on('value', (snapshot) => {
        try {
            const data = snapshot.val()
            commit(mutation, data)
            dispatch('lookupRelated')
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(`Failed subscribing ${topic} to topic`)
        }
    })

const dbRoute = Config.getConfigValue('VUE_APP_FB_DB_ROUTE')

const getFirstRestaurantIdFromUser = (userId, state) => {
    const user = state.users.find((el) => el.id === userId)
    return user && user.userRoles
        ? user.userRoles.reduce((acc, item) => {
              if (acc == undefined)
                  acc =
                      item &&
                      item.restaurant &&
                      item.restaurant.id !== undefined
                          ? item.restaurant.id
                          : undefined
              return acc
          }, undefined)
        : undefined
}

export default {
    namespaced: true,
    state: {
        isInited: false,
        clientConfigs: {},
        clientStatuses: {},
        users: [],
        restaurants: [],
    },

    mutations: {
        setInited: (state, v) => (state.isInited = v),

        setClientConfigs: (state, data) => (state.clientConfigs = data),

        setClientStatuses: (state, data) => (state.clientStatuses = data),

        addUser: (state, user) => state.users.push(user),

        addRestaurant: (state, restaurant) =>
            state.restaurants.push(restaurant),
    },

    actions: {
        async init({ state, commit, dispatch, rootGetters }) {
            if (state.isInited) return
            if (
                rootGetters[authType.getters.GET_ROLES] &&
                Array.isArray(rootGetters[authType.getters.GET_ROLES]) &&
                rootGetters[authType.getters.GET_ROLES].includes(ROLE_ADMIN)
            ) {
                if (await dispatch('login')) {
                    await dispatch('subscribe')
                    commit('setInited', true)
                }
            }
        },

        // @TODO: Extend this, remove hardcoded user, solve user sync with core-api
        async login() {
            return false
        },

        async subscribe({ commit, dispatch }) {
            await subscribeToFirebaseTopic(
                `${dbRoute}/status`,
                commit,
                dispatch,
                'setClientStatuses'
            )
            await subscribeToFirebaseTopic(
                `${dbRoute}/clients`,
                commit,
                dispatch,
                'setClientConfigs'
            )
        },

        async setConfig(_vuex, params = {}) {
            if (params && params.id && params.key) {
                return await Vue.prototype.$fbdb
                    .ref(`${dbRoute}/clients/${params.id}/${params.key}`)
                    .set(params.value)
            }
        },

        async lookupRelated({ state, commit }) {
            const userIds = Object.keys(state.clientConfigs || {})
                .reduce((acc, el) => {
                    acc.push(state.clientConfigs[el].user_id || undefined)
                    return acc
                }, [])
                .filter((el) => el)
                .filter((v, i, a) => a.indexOf(v) === i)
                .filter(
                    (el) => state.users.findIndex((x) => x.id === el) === -1
                )
            if (userIds && Array.isArray(userIds) && userIds.length) {
                const res = await UsersApiClient.list({ id: userIds }).then(
                    (res) => res['hydra:member']
                )
                if (res && Array.isArray(res))
                    res.forEach((el) => {
                        if (state.users.findIndex((x) => x.id === el.id) === -1)
                            commit('addUser', el)
                    })
            }
            const restaurantIds = Object.keys(state.clientConfigs || {})
                .reduce((acc, el) => {
                    let restaurantId =
                        state.clientConfigs[el].restaurant_id || undefined
                    if (
                        restaurantId === undefined &&
                        state.clientConfigs[el].user_id !== undefined
                    )
                        restaurantId = getFirstRestaurantIdFromUser(
                            state.clientConfigs[el].user_id,
                            state
                        )
                    acc.push(restaurantId)
                    return acc
                }, [])
                .filter((el) => el)
                .filter((v, i, a) => a.indexOf(v) === i)
                .filter(
                    (el) =>
                        state.restaurants.findIndex((x) => x.id === el) === -1
                )
            if (
                restaurantIds &&
                Array.isArray(restaurantIds) &&
                restaurantIds.length
            ) {
                const res = await RestaurantsApiClient.list({
                    id: restaurantIds,
                }).then((res) => res['hydra:member'])
                if (res && Array.isArray(res))
                    res.forEach((el) => {
                        if (
                            state.restaurants.findIndex(
                                (x) => x.id === el.id
                            ) === -1
                        )
                            commit('addRestaurant', el)
                    })
            }
        },
    },

    getters: {
        tabletClients: (state) =>
            Object.keys(
                JSON.parse(JSON.stringify(state.clientStatuses || {}))
            ).map((el) => {
                return {
                    id: el,
                    status: state.clientStatuses[el],
                    restaurant: state.restaurants.find(
                        (x) =>
                            state.clientConfigs[el] &&
                            ((state.clientConfigs[el].restaurant_id &&
                                x.id ===
                                    state.clientConfigs[el].restaurant_id) ||
                                (state.clientConfigs[el].restaurant_id ==
                                    undefined &&
                                    state.clientConfigs[el].user_id &&
                                    x.id ===
                                        getFirstRestaurantIdFromUser(
                                            state.clientConfigs[el].user_id,
                                            state
                                        )))
                    ),
                    user: state.users.find(
                        (x) =>
                            state.clientConfigs[el] &&
                            x.id === state.clientConfigs[el].user_id
                    ),
                    ...(state.clientConfigs[el] || {}),
                }
            }),
    },
}
