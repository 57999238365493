import Event from '@/mixins/Event'
import Store from '@/store/store'
import orderType from '@/store/type/orderType'
import { mapGetters } from 'vuex'

export default {
    mixins: [Event],
    data() {
        return {
            channelOrder: null,
            channelDeliveryJobs: null,
            logToConsole: false,
            counter: 0,
        }
    },
    computed: {
        ...mapGetters({
            confirmedList: orderType.getters.CONFIRMED_ORDERS_LIST,
        }),
    },
    mounted() {
        this.channelOrder = this.pusher.subscribe('orders')
        this.channelDeliveryJobs = this.pusher.subscribe('delivery-jobs')

        this.channelOrder.bind('update-order', this.updateOrder)
        this.channelDeliveryJobs.bind(
            'create-delivery-job',
            this.updateDelivery
        )
        this.channelDeliveryJobs.bind(
            'update-delivery-job',
            this.updateDelivery
        )
    },
    beforeDestroy() {
        if (this.pusher) {
            // this.channelOrder.unbind()
            // this.channelDeliveryJobs.unbind()
            // this.pusher.unsubscribe('orders')
            // this.pusher.unsubscribe('delivery-jobs')
            this.pusher.disconnect()
        }
    },
    methods: {
        updateDelivery(data) {
            if (data) {
                const orders = this.findOrdersViaDeliveryJob(data.id)
                let updatedOrder
                if (orders?.length) {
                    orders.forEach((order) => {
                        updatedOrder = {
                            ...order,
                            ...{
                                deliveryJob: this.objectMapper(
                                    order.deliveryJob,
                                    data.data,
                                    this.mapDeliveryJob
                                ),
                            },
                        }
                        Store.commit(
                            orderType.mutations.UPDATE_CONFIRMED_ORDERS_LIST,
                            updatedOrder
                        )
                        Store.commit(
                            orderType.mutations.UPDATE_ACTIVE_ORDER,
                            updatedOrder
                        )
                    })
                }
            }
        },
        updateOrder(data) {
            if (data) {
                const order = this.findOrder(data.identifier)
                if (order) {
                    const updatedOrder = this.objectMapper(
                        order,
                        data.data,
                        this.mapOrder
                    )
                    Store.commit(
                        orderType.mutations.UPDATE_CONFIRMED_ORDERS_LIST,
                        updatedOrder
                    )
                    Store.commit(
                        orderType.mutations.UPDATE_ACTIVE_ORDER,
                        updatedOrder
                    )
                }
            }
        },
        findOrder(identifier) {
            return this.confirmedList?.find(
                (order) => order.identifier === identifier
            )
        },
        findOrdersViaDeliveryJob(id) {
            return this.confirmedList?.filter((order) => {
                return order.deliveryJob?.id === id
            })
        },
    },
}
