<template>
    <div class="admin-unauth">
        <v-app-bar app flat>
            <v-spacer></v-spacer>
            <v-toolbar-title class="unauth-head-title">
                <ordit-logo width="80px"></ordit-logo>
                <span class="d-inline-block ml-4">
                    {{ $t('auth.title') }}
                </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <language-selector></language-selector>
        </v-app-bar>
        <slot></slot>
    </div>
</template>

<script>
import LanguageSelector from './components/LanguageSelector'
import OrditLogo from '@/components/layout/components/OrditLogo'

export default {
    name: 'OrditLayout',
    components: { LanguageSelector, OrditLogo },
}
</script>

<style lang="scss">
.admin-unauth {
    background-color: #faf9f7;
    min-height: 100vh;
}

.unauth-head-title {
    font-size: 24px;
}
</style>
