import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    mixins: [AuthMixin],
    computed: {
        headers() {
            const headers = [
                {
                    value: 'createdAt',
                    text: this.$t('labels.identifier'),
                    type: 'string',
                    width: 120,
                },
                {
                    value: 'status',
                    text: this.$t('labels.status'),
                },
            ]

            if (this.isSuperAdmin) {
                headers.push(
                    {
                        value: 'deliveryJob',
                        text: this.$t('labels.delivery'),
                        sortable: false,
                    },
                    {
                        value: 'deliveryJob.pickupTime',
                        text: this.$t('orders.pickup'),
                        sortable: true,
                    }
                )
            }

            headers.push(
                {
                    value: 'requiredDeliveryTime',
                    text: this.$t('orders.dropoff_at'),
                    type: 'string',
                },
                {
                    value: 'deliveryName',
                    text: this.$t('labels.customer'),
                    type: 'string',
                },
                {
                    value: 'priceTotal',
                    text: this.$t('labels.payment'),
                    sortable: false,
                    type: 'string',
                }
            )

            if (this.isSuperAdmin) {
                headers.push({
                    value: 'sent',
                    text: this.$t('labels.sent_ratio'),
                    sortable: false,
                })
            }

            if (this.isCompanyAdmin) {
                headers.push({
                    value: 'costCode',
                    text: this.$t('labels.cost_code'),
                    sortable: false,
                })
            }
            return headers
        },
    },
}
