<script>
export default {
    name: 'DeliveryStatus',
    props: {
        item: null,
        deliveries: Boolean,
    },
    computed: {
        isDeliveryProcessed() {
            return (
                (this.item?.isDeliveryProcessed && !this.deliveries) ||
                this.deliveries
            )
        },
        deliveryJob() {
            return this.item?.deliveryJob
        },
        updatedAt() {
            return this.deliveryJob?.updatedAt
        },
        status() {
            if (this.deliveries) {
                return this.item?.status
            } else {
                if (this.deliveryJob?.status === 'in_progress') {
                    if (this.deliveryJob?.courierStatus)
                        return this.deliveryJob.courierStatus
                    else return 'searching'
                } else return this.deliveryJob?.status
            }
        },
        isCloudCanteen() {
            return !!this.item.cloudcanteen
        },
        isResidentialCanteen() {
            return !!this.item.residentialcanteen
        },
        isRestaurantManaged() {
            return (
                (!this.deliveryJob ||
                    this.deliveryJob.primaryMethod === 'restaurant_managed') &&
                this.isDeliveryProcessed &&
                !this.deliveries //page
            )
        },
        hasError() {
            return this.deliveries
                ? this.status === 'error'
                : Boolean(
                      this.deliveryJob?.latestCourierError ||
                          this.deliveryJob?.latestCourierErrorDeliveryMethod
                  )
        },
        CError() {
            return this.deliveryJob?.latestCourierError && !this.deliveries
                ? `${this.deliveryJob?.latestCourierError}`
                : ''
        },
        CDMError() {
            return this.deliveryJob?.latestCourierErrorDeliveryMethod &&
                !this.deliveries
                ? `[${this.deliveryJob?.latestCourierErrorDeliveryMethod}] `
                : ''
        },
        errorMessage() {
            return this.deliveries
                ? this.item.error
                : this.CDMError.concat(this.CError)
        },
        color() {
            switch (this.status) {
                case 'initialized':
                case 'queued':
                    return 'g-cc'
                case 'in_progress':
                case 'pending':
                case 'picking':
                case 'almost_picking':
                case 'waiting_at_pickup':
                case 'delivering':
                case 'almost_delivering':
                case 'waiting_at_dropoff':
                    return 'accent'
                case 'finished':
                case 'delivered':
                case 'closed':
                    return 'success'
                case 'cancelled':
                case 'error':
                    return 'error'
                case 'merged':
                case 'grouping':
                case 'searching':
                    return 'info'
                default:
                    return 'yellow'
            }
        },
    },
}
</script>

<template lang="pug">
span(v-if="isRestaurantManaged")
    template(v-if="isCloudCanteen") {{ $t('labels.cloud_canteen') }}
    template(v-else-if="isResidentialCanteen") {{ $t('labels.residential_canteen') }}
    template(v-else) {{ $t('delivery_job_status.restaurant_managed') }}
v-tooltip(:disabled="!(hasError)" v-else-if="isDeliveryProcessed" bottom)
    template(v-slot:activator='{ on, attrs }')
        .d-inline-flex.align-center(v-bind='attrs' v-on='on' :class="{'c-default': ! hasError}")
            v-icon(:color="color" small) location_on
            .d-flex.flex-column(v-if="deliveries")
                |  {{$t(`delivery_job_status.${status}`)}}
            .d-flex.flex-column(v-else)
                template(v-if="deliveryJob && deliveryJob.status === 'in_progress'")
                    | {{$t(`courier_status.${status}`)}}
                template(v-else)
                    |  {{$t(`delivery_job_status.${status}`)}}
                small.d-flex.flex-nowrap.g-73--text(v-if="deliveryJob && deliveryJob.activeDeliveryMethod") {{$t(`active_delivery_method.${deliveryJob.activeDeliveryMethod}`)}}
                small.d-flex.flex-nowrap.g-73--text(v-if="updatedAt") {{ updatedAt | transformDate}}

    span(v-if="hasError") {{errorMessage}}
span(v-else) {{ $t('delivery_job_status.under_process') }}

</template>
