import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import routes from './routes'
import intersection from 'lodash/intersection'
import urlIntegration from './urlIntegration'
import routeType from './routeType'
import authType from '@/store/type/authType'
import qs from 'qs'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from) {
        if (
            !(
                to.name === to.name &&
                to.name === routeType.ORDER_HISTORY &&
                to.params?.history === from.params?.history
            )
        )
            return window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    routes,
    parseQuery(query) {
        return qs.parse(query, {
            decoder(value) {
                if (/^(\d+|\d*\.\d+)$/.test(value)) {
                    return parseFloat(value)
                }
                if (/(%20)+/g.test(value)) {
                    return decodeURI(value)
                }

                let keywords = {
                    true: true,
                    false: false,
                    null: null,
                    undefined: undefined,
                }

                if (value in keywords) return keywords[value]
                return value
            },
        })
    },
    stringifyQuery(query) {
        const result = qs.stringify(query, {
            encode: false,
            arrayFormat: 'brackets',
        })

        return result ? '?' + result : ''
    },
})

// clear 403 page on any navigation
router.beforeEach((to, from, next) => {
    store.commit(authType.mutations.CLEAR_AUTH_ERROR)
    next()
})

// access control
if (window.self === window.top) {
    router.beforeEach((to, from, next) => {
        if (to.meta && to.meta.requiresAuth) {
            if (
                !store.getters[authType.getters.IS_LOGGED_IN] &&
                from.name !== 'login'
            ) {
                return next({
                    name: routeType.LOGIN,
                    replace: true,
                    query: { redirectTo: to.fullPath },
                })
            }
        }

        if (to.meta && to.meta.roles) {
            if (
                intersection(
                    store.getters[authType.getters.GET_ROLES],
                    to.meta.roles
                ).length < 1
            ) {
                return next({ name: 'unauthorized' })
            }
        }

        next()
    })
}

urlIntegration(router)

export default router
