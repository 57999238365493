<script>
import ConfirmOrder from '@/components/pages/restaurantAdmin/confirm/ConfirmOrder'
import ApproveOrder from '@/components/pages/restaurantAdmin/confirm/ApproveOrder'
import { mapGetters } from 'vuex'
import orderType from '@/store/type/orderType'
import ManageOrders from '@/components/mixins/ManageOrders'

const audio = new Audio('/order_confirmation_sound.mp3')
let playAudio
export default {
    name: 'ManageOrders',
    components: {
        ApproveOrder,
        ConfirmOrder,
    },
    mixins: [ManageOrders],
    data() {
        return {
            notAllowed: false,
        }
    },
    computed: {
        ...mapGetters({
            confirmOpen: orderType.getters.CONFIRM_OPEN,
        }),
    },
    watch: {
        confirmOpen: {
            immediate: true,
            handler(openConfirmation) {
                if (openConfirmation) {
                    this.playNotification()
                } else {
                    this.reset()
                }
            },
        },
    },
    mounted() {
        window.addEventListener('focus', this.playOnFocus)
    },
    beforeDestroy() {
        window.removeEventListener('focus', this.playOnFocus)
    },
    methods: {
        playOnFocus() {
            if (this.notAllowed) this.playNotification()
        },
        playNotification() {
            if (playAudio) {
                this.reset()
            }
            this.$nextTick(() => {
                this.play()
                    .then(() => {
                        playAudio = setInterval(this.repeat, 60000)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log(e)
                        //setTimeout(this.playNotification, 3000)
                        this.notAllowed = true
                    })
            })
        },
        play() {
            return new Promise((resolve, reject) => {
                if (this.confirmOpen) {
                    let promise = audio.play()
                    if (promise !== undefined) {
                        promise
                            .then(() => {
                                resolve()
                            })
                            .catch((e) => {
                                reject(e)
                            })
                    }
                } else {
                    this.reset()
                }
            })
        },
        repeat() {
            if (this.confirmOpen) this.play()
            else this.reset()
        },
        reset() {
            audio.pause()
            audio.currentTime = 0
            clearInterval(playAudio)
            playAudio = undefined
        },
    },
}
</script>

<template lang="pug">
.confirm-orders
    approve-order(@order="playNotification")
    confirm-order
</template>

<style scoped lang="scss"></style>
