<template lang="pug">
v-dialog(
    :value="value"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :fullscreen="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
    :content-class="scrollable? undefined : 'overflow-visible'"
    :transition="false"
    @input="$emit('input', $event)"
    persistent )
    slot(name="content")
        v-card(height="100%")
            v-card-title.headline {{ title }}
            v-card-text(:style="{ maxHeight: scrollable ? `60vh` : undefined }")
                slot
            v-card-actions
                v-spacer
                slot(name="actions")
                    cancel-button(v-if="cancelButton" :text="cancelText" @cancel="$emit('cancel'); $emit('input', false)")
                    ok-button(v-if="okButton" :color="okColor" :loading="okLoading" :disabled="okDisabled" :text="okText" @ok="$emit('ok')")

            overlay.modal-overlay(v-if="showOverlay")
</template>

<script>
import Overlay from '@/components/overlay/Overlay'
import CancelButton from './CancelButton'
import OkButton from './OkButton'

export default {
    components: {
        Overlay,
        CancelButton,
        OkButton,
    },
    props: {
        value: {
            type: Boolean,
        },
        showOverlay: {
            type: Boolean,
        },
        title: {
            type: String,
            default: null,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        cancelButton: {
            type: Boolean,
            default: false,
        },
        cancelText: {
            type: String,
            default: null,
        },
        okButton: {
            type: Boolean,
            default: true,
        },
        okLoading: {
            type: Boolean,
        },
        okDisabled: {
            type: Boolean,
            default: false,
        },
        okText: {
            type: String,
            default: null,
        },
        okColor: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: [Number, String],
            default: 500,
        },
    },
    watch: {
        value(value) {
            this.$emit(value ? 'open' : 'close')
        },
    },
}
</script>
<style lang="scss">
.modal-overlay {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
}
</style>
