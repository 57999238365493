<script>
import { mapGetters } from 'vuex'
import Unauthorized from '@/pages/40x/Unauthorized'
import authType from '@/store/type/authType'

export default {
    name: 'MainContent',
    components: { Unauthorized },
    props: {
        isInlined: Boolean,
    },

    computed: {
        ...mapGetters({
            authErrorHappened: authType.getters.AUTH_ERROR_HAPPENED,
        }),
    },
}
</script>

<template lang="pug">
    .main-content(:class="[isInlined ? 'full-page' : '']")
        slot(v-if='!authErrorHappened')
        unauthorized(v-else)
</template>

<style scoped lang="scss"></style>
