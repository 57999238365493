<script>
export default {
    name: 'Status',
    props: {
        item: null,
    },
    computed: {
        status() {
            return this.item?.status
        },
        updatedAt() {
            return this.item?.updatedAt
        },
        color() {
            switch (this.status) {
                case 'init-payment':
                    return 'yellow'
                case 'new':
                    return 'info'
                case 'cancelled':
                    return 'warning'
                case 'cancelled-after-approval':
                    return 'error'
                default:
                    return 'success'
            }
        },
    },
}
</script>

<template lang="pug">
    .d-flex.align-center
        v-icon(:color="color" small) location_on
        .d-flex.flex-column
            | {{ $t(`enums.order_status.${status}`) }}
            small.d-flex.flex-nowrap.g-73--text(v-if="updatedAt") {{updatedAt | transformDate}}

</template>

<style lang="scss">
.editable-field {
    width: 100px;
}
</style>
