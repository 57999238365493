export default {
    getters: {
        CANTEEN: 'canteen',
        CANTEEN_LIST: 'canteenList',
    },
    mutations: {
        SET_CANTEEN: 'setCanteen',
        SET_CANTEEN_LIST: 'setCanteenList',
    },
}
