import cloneDeep from 'lodash/cloneDeep'

class MealFormMapper {
    toEntity(values) {
        const entity = cloneDeep(values)

        // convert to ids
        if (entity['imageMedia'] && entity['imageMedia']['@id']) {
            entity['imageMedia'] = entity['imageMedia']['@id']
        }

        if (entity['thumbMedia'] && entity['thumbMedia']['@id']) {
            entity['thumbMedia'] = entity['thumbMedia']['@id']
        }

        if (!entity['deliveryUnit']) {
            entity['deliveryUnit'] = 1
        }

        if (entity['discountPrice'] == '') {
            entity['discountPrice'] = null
        }

        return entity
    }

    fromEntity(entity) {
        const formData = cloneDeep(entity)

        return formData
    }
}

export default new MealFormMapper()
