<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import printJS from 'print-js'
import canteenOrderIdentifier from '@/mixins/canteenOrderIdentifier'

export default {
    name: 'PrintSummary',
    mixins: [canteenOrderIdentifier],
    props: {
        orderIdentifier: null,
        page: Boolean,
        maxWidth: {
            type: [Number, String],
            default: 500,
        },
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        token() {
            return this.$route.query?.token
        },
        canteenOrderId() {
            return this.getCanteenOrderIdentifier(this.orderIdentifier)
        },
        modalOpen() {
            return this.page || this.open
        },
    },
    mounted() {
        if (this.page && this.orderIdentifier) {
            this.print()
        }
    },
    methods: {
        print() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadSummary(this.orderIdentifier, this.token)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    printJS(blobUrl)
                })
                .catch(console.error)
        },
        download() {
            RestApiCollection.get(RestApiType.ORDERS)
                .downloadSummary(this.orderIdentifier, this.token)
                .then((response) => {
                    const blob = new Blob([response], {
                        type: 'application/pdf',
                    })
                    const blobUrl = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    link.download = this.canteenOrderId
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(console.error)
        },
    },
}
</script>

<template>
    <div>
        <div v-if="orderIdentifier && !page" class="print-label">
            <v-btn color="primary" small @click="open = true">
                {{ $t('orders.summary_print') }}
                <v-icon class="ml-1" small>print</v-icon>
            </v-btn>
        </div>
        <v-dialog
            :value="modalOpen"
            :max-width="maxWidth"
            persistent
            :fullscreen="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
            :transition="false"
            @input="open = false"
        >
            <slot name="content">
                <v-card height="100%">
                    <v-card-title class="headline">
                        {{ $t('orders.summary_modal_title') }}
                        <v-spacer></v-spacer>
                        <v-btn v-show="!page" icon @click="open = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <div>
                            {{ $t('orders.summary') }} #{{ canteenOrderId }}
                        </div>
                    </v-card-text>
                    <v-card-actions class="mx-auto">
                        <v-spacer></v-spacer>
                        <slot name="actions">
                            <v-btn depressed color="info" @click="download">
                                {{ $t('orders.summary_download') }}
                                <v-icon class="ml-1" small
                                    >picture_as_pdf</v-icon
                                >
                            </v-btn>
                            <v-btn depressed color="primary" @click="print">
                                {{ $t('orders.summary_print') }}
                                <v-icon class="ml-1" small>print</v-icon>
                            </v-btn>
                        </slot>
                    </v-card-actions>
                </v-card>
            </slot>
        </v-dialog>
    </div>
</template>
