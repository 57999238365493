import { mapGetters } from 'vuex'
import { EventBus } from '@/plugins/events'
import authType from '@/store/type/authType'
import configType from '@/store/type/configType'
import orderType from '@/store/type/orderType'

export default {
    computed: {
        ...mapGetters({
            notificationRegistered: authType.getters.GET_NOTIFICATION_STATE,
            notificationPermissionGranted:
                configType.getters.NOTIFICATION_PERMISSION_GRANTED,
        }),
    },
    mounted() {
        EventBus.$on('notificationsOn', this.onIncomingOrder)
        this.getNewOrders()
        this.getOrders()
        // this.getConfirmedOrders()
        if (!this.$cookies.get('notificationOffByUser')) {
            this.$store
                .dispatch(
                    configType.actions.SET_NOTIFICATION_PERMISSION,
                    Notification.permission === 'granted'
                )
                .then(() => {
                    if (this.notificationPermissionGranted) {
                        this.$store
                            .dispatch(authType.actions.REGISTER_NOTIFICATIONS)
                            .then(() => {
                                this.onIncomingOrder()
                            })
                            .catch((e) => {
                                // eslint-disable-next-line no-console
                                console.log(e)
                            })
                    }
                })
        }
    },
    beforeDestroy() {
        EventBus.$off('notificationsOn', this.onIncomingOrder)
    },
    methods: {
        onIncomingOrder() {
            navigator.serviceWorker.onmessage = () => {
                this.getNewOrders()
            }
        },
        getOrders() {
            this.$store.dispatch(orderType.actions.GET_ORDERS)
        },
        getConfirmedOrders() {
            this.$store.dispatch(orderType.actions.GET_CONFIRMED_ORDERS)
        },
        getNewOrders() {
            this.$store
                .dispatch(orderType.actions.GET_NEW_ORDERS)
                .then((list) => {
                    if (list?.length) {
                        this.$store.dispatch(orderType.actions.GET_ORDER, [
                            list[0].id,
                            orderType.mutations.UPDATE_NEW_ORDERS_LIST,
                        ])
                    }
                })
        },
    },
}
