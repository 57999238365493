<script>
import OrditLogo from '@/components/layout/components/OrditLogo'
import { Printd } from 'printd'

export default {
    name: 'PrintReceipt',
    components: { OrditLogo },
    props: {
        order: null,
    },
    data() {
        return {
            printd: null,
            css: `
                    #print {
                        margin: 0;
                        background: white;
                        font-size: 14px;
                        font-family: "Roboto", sans-serif;
                        padding: 0;
                    }
                     #print .receipt-logo {
                        display: block;
                        margin: auto;
                    }
                     #print .receipt-r-name {
                        margin: 10px 0;
                        font-weight: normal;
                        text-align: center;
                    }
                     #print .receipt-id {
                        text-align: center;
                        font-size: 2em;
                    }
                     #print .receipt-id span {
                        font-weight: 500;
                    }
                     #print .receipt-hr {
                        display: block;
                        width: 100%;
                        border-bottom: 1px dashed black;
                        margin: 10px 0;
                    }
                     #print .receipt-notes label {
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                     #print .receipt-notes p {
                        margin: 0;
                    }
                    #print .receipt-order-details{
                        display: flex;
                    }
                    #print .receipt-order-details label {
                        padding-right: 3px;
                    }
                     #print .receipt-price-details {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                    }
                     #print .receipt-meal {
                        display: flex;
                        flex-wrap: wrap;
                    }
                     #print .receipt-meal .receipt-meal-quantity {
                        width: 2em;
                    }
                     #print .receipt-meal .receipt-meal-name {
                        flex: 2;
                    }
                     #print .receipt-meal .receipt-meal-price {
                        flex: 1;
                        text-align: right;
                    }
                     #print .receipt-meal .receipt-meal-options {
                        padding-left: 15px;
                        color: #737373;
                        width: 100%;
                    }
                     #print .receipt-meal .receipt-meal-options .receipt-meal-option {
                        display: flex;
                    }
                     #print .receipt-meal .receipt-meal-options .receipt-meal-option span:first-child {
                        padding-right: 3px;
                    }
                     #print .receipt-meal .receipt-meal-options .receipt-meal-option span:last-child {
                        padding-left: .125em;
                    }
                    #print .receipt-paid {
                        text-align: center;
                        font-weight: 500;
                    }
                    `,
        }
    },
    computed: {
        meals() {
            return this.order?.meals
        },
        deliveryByYou() {
            return !(
                this.order?.deliveryJob &&
                this.order?.deliveryJob.primaryDeliveryMethod !==
                    'restaurant_managed'
            )
        },

        isCash() {
            return (
                this.order?.paymentMethod === 'cash' ||
                this.order?.paymentMethod === 'cash' ||
                this.order?.paymentMethod === 'sodexo-pass' ||
                this.order?.paymentMethod === 'ticket-restaurant'
            )
        },
        discount() {
            return (
                (this.order?.priceCouponDiscount
                    ? parseInt(this.order?.priceCouponDiscount)
                    : 0) +
                this.order?.userDiscount +
                this.order?.firmDiscount
            )
        },
    },
    mounted() {
        this.printd = new Printd()
    },
    methods: {
        print() {
            this.printd.print(this.$refs.print, [this.css])
        },
    },
}
</script>

<template lang="pug">
    .print-receipt(v-if="order")
        v-btn(color="primary" @click="print" small) {{ $t('orders.print_receipt') }}
            v-icon.ml-1(small) print
        #print.d-none(ref="print")
            ordit-logo.receipt-logo(width="80px")
            h3.receipt-r-name {{ order.restaurantName }}
            h3.receipt-id {{'#'}}{{ order.identifierForRestaurant }}
            .receipt-hr
            .receipt-notes(v-if="deliveryByYou")
                label {{ $t('labels.address') }}
                div {{ order.deliveryFullAddress }}
                    br
                    | {{ order.deliverySubAddress }}
            .receipt-hr(v-if="deliveryByYou")
            .receipt-notes
                label {{ $t('orders.delivery_notes') }}
                div {{ order.comment }}
            .receipt-hr
            .receipt-order-details
                label {{ $t('orders.submitted') }}:&nbsp;
                span {{ order.createdAt | toDate }}
            .receipt-order-details
                label {{ $t('orders.customer') }}:&nbsp;
                span {{ order.deliveryName }}
            .receipt-order-details(v-if="order.phone")
                label {{ $t('labels.phone') }}:&nbsp;
                span {{ order.phone }}
            .receipt-hr
            .receipt-price-details
                span {{ $t('orders.total') }}
                span {{ order.priceTotal | currency }}
            .receipt-meal(v-for="(meal, index) in meals" :key="`receiptMeal-${index}`")
                .receipt-meal-quantity {{ meal.quantity }}x
                .receipt-meal-name(v-if="meal.meal") {{ meal.meal.name }}
                .receipt-meal-price {{ meal.price * meal.quantity | currency }}
                .receipt-meal-options(v-if="meal.children.length")
                    .receipt-meal-option(v-for="(option, index) in meal.children" :key="`receiptMealOption-${index}`")
                        span {{option.quantity}}x
                        span {{option.mealoption.name}}
                        span.price {{ option.price * option.quantity * meal.quantity | currency}}
            .receipt-hr
            .receipt-price-details
                span {{ $t('orders.subtotal') }}
                span {{ order.priceSumItems | currency }}
            .receipt-price-details
                span {{ $t('labels.delivery_fee') }}
                span {{ order.priceDelivery | currency }}
            .receipt-price-details(v-if="discount > 0")
                span {{ $t('labels.discount') }}
                span -{{ discount | currency }}
            .receipt-hr
            .receipt-price-details(v-if="isCash")
                span(v-if="isCash") {{ $t('orders.cash_payment_receipt') }}
                span {{ order.priceSumUser | currency }}
            h3.receipt-paid(v-else) {{ $t('orders.paid') }}

</template>

<style scoped lang="scss"></style>
