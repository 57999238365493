import Vue from 'vue'
import configType from './type/orderType'
import RestApiCollection from '@/api/RestApiCollection'
import Notifications from '@/services/Notification/Notification'
import RestApiType from '@/api/RestApiType'
import { i18n } from '@/plugins/i18n'
import router from '@/router/router'

let blockApproveOrders = false
let blockNewOrders = false

const api = RestApiType.ORDERS

let loaderStates = Object.fromEntries(
    Object.values(configType.loaders).map((loader) => [loader, false])
)

let loaderGetters = {}
Object.values(configType.loaders).forEach((value) => {
    loaderGetters[value] = (state) => {
        return state[value]
    }
})

export default {
    state: {
        activeOrder: null,
        newOrdersList: [],
        historyOrdersList: [],
        approvedOrdersList: [], // approved but not confirmed orders
        confirmedOrdersList: [], // confirmed orders
        approveOpen: false,
        confirmOpen: false,
        ...loaderStates,
    },
    getters: {
        [configType.getters.ACTIVE_ORDER](state) {
            return state.activeOrder
        },
        [configType.getters.NEW_ORDERS_LIST](state) {
            return state.newOrdersList
        },
        [configType.getters.HISTORY_ORDERS_LIST](state) {
            return state.historyOrdersList
        },
        // approved but not confirmed orders
        [configType.getters.APPROVED_ORDERS_LIST](state) {
            return state.approvedOrdersList.sort((a, b) => {
                return Vue.prototype
                    .$date(a.startPrepareAt)
                    .isBefore(b.startPrepareAt)
                    ? -1
                    : 1
            })
        },
        // confirmed orders
        [configType.getters.CONFIRMED_ORDERS_LIST](state) {
            return state.confirmedOrdersList.sort((a, b) => {
                return Vue.prototype
                    .$date(a.startPrepareAt)
                    .isBefore(b.startPrepareAt)
                    ? -1
                    : 1
            })
        },
        [configType.getters.APPROVE_OPEN](state) {
            return state.approveOpen
        },
        [configType.getters.CONFIRM_OPEN](state) {
            return state.confirmOpen
        },
        ...loaderGetters,
    },
    mutations: {
        [configType.mutations.SET_ACTIVE_ORDER](state, payload) {
            state.activeOrder = payload
        },
        [configType.mutations.SET_CONFIRMED_ORDERS_LIST](state, payload) {
            state.confirmedOrdersList = payload
        },
        [configType.mutations.SET_NEW_ORDERS_LIST](state, payload) {
            state.newOrdersList = payload
        },
        [configType.mutations.SET_HISTORY_ORDERS_LIST](state, payload) {
            state.historyOrdersList = payload
        },
        [configType.mutations.SET_APPROVED_ORDERS_LIST](state, payload) {
            state.approvedOrdersList = payload
        },
        [configType.mutations.SET_INCOMING_CONFIRM_STATE](state, payload) {
            state.approveOpen = payload
        },
        [configType.mutations.SET_CONFIRM_STATE](state, payload) {
            state.confirmOpen = payload
        },
        [configType.mutations.UPDATE_NEW_ORDERS_LIST](state, payload) {
            let index = state.newOrdersList.findIndex(
                (order) => order.id === payload.id
            )
            Vue.set(state.newOrdersList, index, payload)
        },
        [configType.mutations.UPDATE_APPROVED_ORDERS_LIST](state, payload) {
            let index = state.approvedOrdersList.findIndex(
                (order) => order.identifier === payload.identifier
            )
            if (index > -1) {
                Vue.set(state.approvedOrdersList, index, payload)
            }
        },
        [configType.mutations.UPDATE_CONFIRMED_ORDERS_LIST](state, payload) {
            let index = state.confirmedOrdersList.findIndex(
                (order) => order.identifier === payload.identifier
            )
            if (index > -1) {
                if (payload.isActiveForRestaurant)
                    Vue.set(state.confirmedOrdersList, index, payload)
                else {
                    state.confirmedOrdersList.splice(index, 1)
                    if (state.activeOrder?.id === payload.id) {
                        let showOrder =
                            state.confirmedOrdersList[0] ||
                            state.approvedOrdersList[0]
                        if (showOrder) {
                            router.replace({ params: { id: showOrder.id } })
                        } else {
                            state.activeOrder = null
                        }
                    }
                }
            }
        },
        [configType.mutations.UPDATE_ACTIVE_ORDER](state, payload) {
            if (state.activeOrder?.id === payload.id)
                state.activeOrder = { ...state.activeOrder, ...payload }
        },
        [configType.mutations.SET_LOADING](state, { loader, loading }) {
            state[loader] = loading
        },
    },
    actions: {
        [configType.actions.SET_HISTORY_ORDERS_LIST]({ commit }, payload) {
            commit(configType.mutations.SET_HISTORY_ORDERS_LIST, payload)
        },
        async [configType.actions.GET_ORDER]({ commit }, [id, list]) {
            const loader = configType.loaders.GET_ORDER_LOADING
            commit(configType.mutations.SET_LOADING, {
                loader: loader,
                loading: true,
            })
            try {
                const result = await RestApiCollection.get(api).get(id)
                if (list) commit(list, result)
                else {
                    commit(configType.mutations.SET_ACTIVE_ORDER, result)
                }

                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.resolve(result)
            } catch (e) {
                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.reject(e)
            }
        },

        async [configType.actions.GET_NEW_ORDERS]({ commit }) {
            if (!blockNewOrders) {
                blockNewOrders = true
                const loader = configType.loaders.GET_NEW_ORDERS_LOADING
                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: true,
                })
                try {
                    const result = await RestApiCollection.get(api).list({
                        status: 'new',
                        itemsPerPage: 100,
                    })
                    const list = result['hydra:member']

                    commit(configType.mutations.SET_NEW_ORDERS_LIST, list)
                    commit(configType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockNewOrders = false
                    return Promise.resolve(list)
                } catch (e) {
                    commit(configType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockNewOrders = false
                    return Promise.reject(e)
                }
            }
        },
        // approved AND confirmed orders
        async [configType.actions.GET_ORDERS]({ commit, state }) {
            if (!blockApproveOrders) {
                blockApproveOrders = true
                const loader = configType.loaders.GET_ORDERS_LOADING
                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: true,
                })
                try {
                    // const date = Vue.prototype.$date().add(0, 'minute').format()
                    const result = await RestApiCollection.get(api).list({
                        activeOrders: true,
                        // status: 'approved',
                        itemsPerPage: 100,
                    })
                    const list = result['hydra:member']

                    commit(
                        configType.mutations.SET_APPROVED_ORDERS_LIST,
                        list.filter((order) => !order.preparationConfirmed)
                    )
                    commit(
                        configType.mutations.SET_CONFIRMED_ORDERS_LIST,
                        list.filter((order) => order.preparationConfirmed)
                    )
                    let activeOrderIsNotInList =
                        (state.activeOrder &&
                            !list.find(
                                (item) => item.id === state.activeOrder?.id
                            )) ||
                        !list.length
                    if (activeOrderIsNotInList) {
                        commit(configType.mutations.SET_ACTIVE_ORDER, null)
                    }

                    commit(configType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockApproveOrders = false
                    return Promise.resolve(list)
                } catch (e) {
                    commit(configType.mutations.SET_LOADING, {
                        loader: loader,
                        loading: false,
                    })
                    blockApproveOrders = false
                    return Promise.reject(e)
                }
            }
        },
        // ONLY confirmed orders
        async [configType.actions.GET_CONFIRMED_ORDERS]({ commit, state }) {
            const loader = configType.loaders.GET_CONFIRMED_ORDERS_LOADING
            commit(configType.mutations.SET_LOADING, {
                loader: loader,
                loading: true,
            })
            try {
                // const date = Vue.prototype.$date().add(30, 'minute').format()
                const result = await RestApiCollection.get(api).list({
                    activeOrders: true,
                    preparationConfirmed: true,
                    itemsPerPage: 100,
                })
                const list = result['hydra:member']

                commit(configType.mutations.SET_CONFIRMED_ORDERS_LIST, list)
                let activeOrderIsNotInList =
                    (state.activeOrder &&
                        !list.find(
                            (item) => item.id === state.activeOrder?.id
                        ) &&
                        !state.approvedOrdersList.find(
                            (item) => item.id === state.activeOrder?.id
                        )) ||
                    !list.length
                if (activeOrderIsNotInList) {
                    commit(configType.mutations.SET_ACTIVE_ORDER, null)
                }

                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.resolve(list)
            } catch (e) {
                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.reject(e)
            }
        },
        async [configType.actions.APPROVE_ORDER]({ commit }, [id, time]) {
            const loader = configType.loaders.APPROVE_ORDER_LOADING
            commit(configType.mutations.SET_LOADING, {
                loader: loader,
                loading: true,
            })
            try {
                const result = await RestApiCollection.get(api).approve(id, {
                    delivery: {
                        preparation_time: time,
                    },
                })
                if (result.already_approved)
                    Notifications.warning(
                        i18n.t('labels.attention'),
                        i18n.t('orders.already_approved')
                    )

                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.resolve(result)
            } catch (e) {
                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.reject(e)
            }
        },
        async [configType.actions.CONFIRM_ORDER]({ commit }, id) {
            const loader = configType.loaders.CONFIRM_ORDER_LOADING
            commit(configType.mutations.SET_LOADING, {
                loader: loader,
                loading: true,
            })
            try {
                const result = await RestApiCollection.get(api).confirm(id)

                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.resolve(result)
            } catch (e) {
                commit(configType.mutations.SET_LOADING, {
                    loader: loader,
                    loading: false,
                })
                return Promise.reject(e)
            }
        },
    },
}
