export default {
    USERS_FORM: 'usersForm',
    PROMOTIONS_FORM: 'promotionsForm',
    COMPANIES_FORM: 'companiesForm',
    COMPANY_ADDRESSES_FORM: 'companyAddressesForm',
    COMPANY_CLOUDCANTEENS_FORM: 'companyCloudcanteensForm',
    COMPANY_TEAMS_FORM: 'companyTeamsForm',
    COMPANY_BALANCES_FORM: 'companyBalancesForm',
    USER_ADDRESSES_FORM: 'userAddressesForm',
    COMPANY_ALLOWANCES_FORM: 'companyAllowancesForm',
    RESTAURANT_FORM: 'restaurantForm',
    MEAL_CATEGORY_LIST: 'mealCategoryList',
    MEAL_LIST: 'mealList',
    MEAL_OPTION_GROUP: 'mealOptionGroupList',
    MEAL_OPTION: 'mealOptionList',
    MEAL_FORM: 'mealForm',
    ORDER_FORM: 'orderForm',
    TAG_FORM: 'tagForm',
    TAG_GROUP_FORM: 'tagGroupForm',
    DELIVERY_FORM: 'deliveryForm',
    CUSTOM_EXPORT_FORM: 'customExportForm',
    COUPON_FORM: 'couponForm',
    RESIDENTIAL_CANTEENS_FORM: 'residentialCanteensForm',
    RESIDENTIAL_CANTEEN_ADDRESSES_FORM: 'residentialCanteenAddressesForm',
    RESIDENTIAL_CANTEEN_RELATIONS_FORM: 'residentialCanteenRelationsForm',
    INVOICE_FORM: 'invoiceForm',
}
