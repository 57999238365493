import Vue from 'vue'

Vue.filter('toSchedule', (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return 'String value only!'
    value = Vue.prototype.$generalizeDate(value)
    const now = Vue.prototype.$date()
    const date = Vue.prototype.$date(value)
    if (date.isBefore(now, 'day'))
        return `<span>${date.format('D MMM YYYY')},</span>&nbsp;${date.format(
            'HH:mm'
        )}`
    else if (date.isSame(now, 'day'))
        return `<span>Today</span>&nbsp;${date.format('HH:mm')}`
    else if (date.isSame(now.add(1, 'days'), 'day'))
        return `<span>Tomorrow</span>&nbsp;${date.format('HH:mm')}`
    else if (date.isSame(now, 'year'))
        return `<span>${date.format('D MMM')}</span>&nbsp;${date.format(
            'HH:mm'
        )}`
    return `<span>${date.format('D MMM YYYY')}</span>&nbsp;${date.format(
        'HH:mm'
    )}`
})
