export default {
    getters: {
        ACTIVE_ORDER: 'activeOrder',
        ORDERS_LIST: 'ordersList',
        NEW_ORDERS_LIST: 'newOrdersList',
        HISTORY_ORDERS_LIST: 'historyOrdersList',
        APPROVED_ORDERS_LIST: 'approvedOrdersList',
        CONFIRMED_ORDERS_LIST: 'confirmedOrdersList',
        APPROVE_OPEN: 'approveOpen',
        CONFIRM_OPEN: 'prepareConfirmOpen',
    },
    mutations: {
        SET_ACTIVE_ORDER: 'setActiveOrder',
        SET_CONFIRMED_ORDERS_LIST: 'setConfirmedOrdersList',
        SET_NEW_ORDERS_LIST: 'setNewOrdersList',
        UPDATE_NEW_ORDERS_LIST: 'updateNewOrdersList',
        SET_HISTORY_ORDERS_LIST: 'setHistoryOrderList',
        SET_APPROVED_ORDERS_LIST: 'setApprovedOrdersList',
        SET_INCOMING_CONFIRM_STATE: 'setIncomingConfirmState',
        SET_CONFIRM_STATE: 'setPrepareConfirmState',
        UPDATE_APPROVED_ORDERS_LIST: 'updateApprovedOrdersList',
        UPDATE_CONFIRMED_ORDERS_LIST: 'updateConfirmedOrdersList',
        UPDATE_ACTIVE_ORDER: 'updateActiveOrder',
        SET_LOADING: 'setLoading',
    },
    actions: {
        GET_ORDER: 'getOrder',
        GET_NEW_ORDERS: 'getNewOrders',
        GET_ORDERS: 'getOrders',
        GET_CONFIRMED_ORDERS: 'getConfirmedOrders',
        SET_HISTORY_ORDERS_LIST: 'setHistoryOrderList',
        UPDATE_APPROVED_ORDERS_LIST: 'updateApprovedOrdersList',
        APPROVE_ORDER: 'approveOrder',
        CONFIRM_ORDER: 'confirmOrder',
    },
    loaders: {
        APPROVE_ORDER_LOADING: 'approveOrderLoading',
        CONFIRM_ORDER_LOADING: 'confirmOrderLoading',
        GET_NEW_ORDERS_LOADING: 'getNewOrdersLoading',
        GET_ORDERS_LOADING: 'getNewOrdersLoading',
        GET_CONFIRMED_ORDERS_LOADING: 'getConfirmedOrdersLoading',
        GET_ORDER_LOADING: 'getOrderLoading',
    },
}
